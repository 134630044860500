import httpcommon from "../services/httpcommon";
import adlogin from '../services/httpcommon'
import axios from "axios";
import { encryptData } from "../assets/encryptDecrypt";

class ApiService {
    async getUserConsent() {
        try {
            const configurationObject = {
                method: 'GET',
                url: process.env.REACT_APP_CMS_VERSION_URL,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios(configurationObject);
            return response.data;
        } catch (error) {
            console.log('Warning', error);
            return error.response;
        }
    }
    async getToken(formData, OTP) {
        try {
            const { userCode, userMobileNumber, passWord, userOTP } = formData;

            const params = new URLSearchParams();
            params.append('username', userCode || userMobileNumber);

            let passwordValue;

            if (passWord !== undefined && passWord !== null && passWord !== '') {
                passwordValue = btoa(passWord);
            } else if (userOTP !== undefined && userOTP !== null && userOTP !== '') {
                passwordValue = userOTP;
            } else {

                passwordValue = OTP;
            }

            params.append('password', passwordValue);
            params.append('grant_type', 'password');

            const credentials = 'curebay:cGFzc3dvcmQ=';
            const encodedCredentials = window.btoa(credentials);
            let payload1 = {
                userCode: userCode,
                passWord: encodedCredentials,
                channel: 'W'
              }
              console.log(payload1)
              const encryptedData = encryptData(payload1);

            const response = await httpcommon.post('/gcp/oauth/token', encryptedData.toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'grant_type': 'password',
                    'Authorization': `Basic ${encodedCredentials}`
                },
            });

            return response || null;
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async userInfo(userCode) {
        try {
            const params = new URLSearchParams();

            params.append('userId', userCode);
            params.append('status', 1);
            const response = await httpcommon.get(`/User_Hospital_Location/list?${params.toString()}`, {})
            if (response) {
                return response;
            }
            else {
                console.error('API Error:', response);
                return null;
            }
        }
        catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async logincbayuser(formData) {
        const parsedFormData = JSON.parse(formData);

        const filteredFormData = Object.fromEntries(
            Object.entries(parsedFormData).filter(([key, value]) => value !== undefined && value !== null && value !== '')
        );
        if (filteredFormData.passWord) {
            filteredFormData.passWord = btoa(filteredFormData.passWord);
        }

        try {
            const response = await httpcommon.post('Login/withAd', JSON.stringify(filteredFormData));
            console.log(response)
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async loginApi(formData) {
        const parsedFormData = JSON.parse(formData);

        const filteredFormData = Object.fromEntries(
            Object.entries(parsedFormData).filter(([key, value]) => value !== undefined && value !== null && value !== '')
        );
        if (filteredFormData.passWord) {
            filteredFormData.passWord = btoa(filteredFormData.passWord);
            filteredFormData.roleCode = 'PRADMIN'
        }

        try {
            const encryptedData = encryptData(JSON.stringify(filteredFormData));

            const response = await httpcommon.post('Login/withOutAd', encryptedData);

            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async generateOtp(payload) {
        try {
            const response = await httpcommon.post(`UserOTP/generateUserOTP/${payload.mobileNumber}/${payload.mobileCheck}`);
            if (response) {
                return response;
            } else {
                console.error('API Error:', response);
                return null;
            }
        }
        catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getApiCMS(id) {
        const NewCmsToken = 'Bearer 6f1797939dbb00599d8896d29f4e114b257e1e252d6856294951bdcdd7be292b7b49d6a266f2319d74e024cac8a99de3ab5256b82e25d4bb931a5c44a28f8d5ddcdffc51ee8abad8f62ac14a985397c4636b7575a9709d1cd6ae4bed4419494c2ea96ed3bc6223a5bac20e6a07342f7f17fb1cc7c87dccd85c0de0930896afe1'
        try {
            const configurationObject = {
                method: 'GET',
                url: process.env.REACT_APP_USER_CMS_URL + `&userId=${id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: NewCmsToken,
                },
            };
            const response = await axios(configurationObject);
            return response.data;
        } catch (error) {
            console.log('Warning', error);
            return error.response;
        }
    }

    async userConsentPost(payload) {
        let s_token = localStorage.getItem('access_token');
        try {
            const configurationObject = {
                method: 'POST',
                url: process.env.REACT_APP_USER_ADDCONSENT,
                data: payload,
                headers: {
                    Authorization: `Bearer ${s_token ? s_token : ''}`,}
            };
            const response = await axios(configurationObject);
            return response.data;
        } catch (error) {
            console.log('Warning', error);
            return error.response;
        }
    }
    async adloginApi(formData) {
        const parsedFormData = formData

        const filteredFormData = Object.fromEntries(
            Object.entries(parsedFormData).filter(([key, value]) => value !== undefined && value !== null && value !== '')
        );
        if (filteredFormData.passWord) {
            filteredFormData.password = filteredFormData.passWord
        }
        filteredFormData.username = filteredFormData.userCode
        delete filteredFormData.userCode
        delete filteredFormData.passWord
        try {
            const response = await adlogin.post('https://adapi-stage.curebay.in/commonlogin/api/validateUser', JSON.stringify(filteredFormData));

            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }


    }
    async forgetPassword(filterDetails) {
        try {
            const response = await httpcommon.post('PasswordMaintenance/forgetpassword/', filterDetails);
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async loginUsingMobile(formData) {
        const parsedFormData = JSON.parse(formData);
        if (parsedFormData.otp) {
            parsedFormData.passWord = btoa(parsedFormData.passWord);
        }

        try {
            const response = await httpcommon.post('/Login/UserLoginWithMobile/', JSON.stringify(parsedFormData));
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async getenqList(payload) {
        try {
            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");

            const response = await httpcommon.get(`/PatientServiceEnquiryForm/list?${queryString}`, {})
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return error;
        }
    }
    async expenqList(payload) {
        try {
            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");

            const response = await httpcommon.get(`/PatientServiceEnquiryForm/list/export?${queryString}`, {})
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return error;
        }
    }

    async exportNewEenqList(payload) {
        try {
            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");

            const response = await httpcommon.get(`/PatientServiceEnquiryForm/list/conciergeUserNewExport?${queryString}`, {})
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return error;
        }
    }
    async exportServiceList(payload) {
        try {
            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");

            const response = await httpcommon.get(`/PracticeAdminServiceDetails/list/export?${queryString}`, {})
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return error;
        }
    }
    async enquiryStatusUpdate(payload) {
        try {
            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");

            const response = await httpcommon.put(`PatientServiceEnquiryForm/updateApproveEnquiryStatusBatch/`, payload)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return error;
        }
    }
    
    async billingStatusUpdate(payload) {
        try {
            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");

            const response = await httpcommon.put(`PatientServiceEnquiryForm/updateApproveEnquiryStatusBatch/`, payload)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return error;
        }
    }
    async billingApprovalUpdate(payload) {
        try {
            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");

            const response = await httpcommon.put(`PracticeAdminServiceDetails/billingStatusUpdateBatch/`, payload)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return error;
        }
    }
    async getenqCElist(payload) {
        try {
     
            var queryString = Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&");
            const response = await httpcommon.get(`/PatientServiceEnquiryForm/list/conciergeUserNew?${queryString}`, {})
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getServicesBillList(payload) {
        try {
            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");

            const response = await httpcommon.get(`/PracticeAdminServiceDetails/list/filter?${queryString}`, {})
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getServicesBillListByPatient(payload) {
        try {
            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");

            const response = await httpcommon.get(`/PracticeAdminServiceDetails/billcountByPatient?${queryString}`, {})
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async geteServices(payload) {
        try {
            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");

            const response = await httpcommon.get(`/Services/list/filter?${queryString}`)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async getprocedureList() {
        try {


            const response = await httpcommon.get(`/Procedure/list`)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getMdmUserlist(payload){
        try {
            var queryString = Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&");
        
            const response = await httpcommon.get(`surgeryDoctorlist/list?${queryString}`)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return error;
        }
}
    
    async dashBoardCount(payload) {
        try {
            var queryString = Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&");

            const response = await httpcommon.get(`/PatientServiceEnquiryForm/dashboard?${queryString}`)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async billigstatusCount(payload) {
        try {
            var queryString = Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&");

            const response = await httpcommon.get(`/PracticeAdminServiceDetails/dashboard/billingstatus?${queryString}`)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    getDoctorList = async (payload) => {
        try {

            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");
            const response = await httpcommon.get(`User_Hospital_Location/Users/list?${queryString}`)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    
    async saveService(payload) {
        try {
            const response = await httpcommon.post('PracticeAdminServiceDetails/', payload);
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return error;
        }
    }

    async billingChange(payload) {
        try {
            const response = await httpcommon.put('PracticeAdminServiceDetails/billingStatusUpdate/', payload);
            if (response?.status === 200) {
                return response;
            } else {
                console.error('API Error:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return error;
        }
    }

    getDoctorList2 = async (payload) => {
        try {

            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");
            const response = await httpcommon.get(`user/list/filter?${queryString}`)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    getHospitaliztionList = async (payload) => {
        try {

            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");
            const response = await adlogin.get(`${process.env.REACT_APP_NEW_BASEURL}hospitalizationServiceType/list/filter?${queryString}`)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getHospitalList(payload) {
        try {
            var queryString = Object.keys(payload)
                .map((key) => key + "=" + payload[key])
                .join("&");

            const response = await httpcommon.get(`/location/list/filter1?${queryString}`, {})
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }


    async getUserList(payload) {
        try {
            var queryString = Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&");

            const response = await httpcommon.get(`/user/list/filter/roles?${queryString}`, {},)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async updateEnqList(payload) {
        try {


            const response = await httpcommon.put(`PatientServiceEnquiryForm/update1/`, payload)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }

    async updateAssidgn(payload) {
        try {


            const response = await httpcommon.put(`PatientServiceEnquiryForm/updateAssignedUserList/`, payload)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
    async getApproval(payload, id) {


        // return this.http.put('PatientServiceEnquiryForm/approveEnquiryForm/' +id, payload);
        try {


            const response = await httpcommon.put(`PatientServiceEnquiryForm/approveEnquiryForm/` + id, payload)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }

    }
    getHosSubType(payload) {
        const url = `${process.env.REACT_APP_NEW_BASEURL}hospitalizationServiceType/list/filter?serviceType=${payload.serviceId}&hospitalizationTypeId=${payload.hosId}`
        return httpcommon.get(url);
    }
    async viewStatusUpdate(payload, id) {
        try {
            const response = await httpcommon.put(`PatientServiceEnquiryForm/viewStatus/` + id, payload)
            if (response) {
                return response;
            }
        } catch (error) {
            console.error('API Request Error:', error);
            return null;
        }
    }
}
const apiService = new ApiService();
export default apiService;
