import React from "react";
import Header from "../Shared/header";
import NavBar from "../NavigationBar/NavBar";

function OurLayouts({ children }) {
  return (
    // <div className="flex w-full">
    //     <Header />
    //     <NavBar />
    //     <div className="" style={{width: "100%"}}>{children}</div>
    //    </div>
    <div className="flex w-full ">
      <div className="w-[15%] h-full ">
        <NavBar />
      </div>
      <div className="w-[85%] bg-primary overflow-x-hidden ">
        <div className="rounded-t-3xl rounded-b-3xl bg-white ">
          <Header className="w-full" />
          <div className="ml-5 w-full h-[100vh] overflow-y-auto overflow-x-hidden">
            {children}
          </div>
        </div>
      </div>
    </div>

  )
}

export default OurLayouts;
