import http from "../httpcommon";

class profileServices {
  getDataAPI(id) {
    return http.get(`user/${id}`)
  }
  getStateList() {
    return http.get(`state/list`);
  }
  updateUserdata(payload) {
    return http.put(`user/${payload.id}`, payload);
  }
  getUserConsentData(payload){
    const url = `${process.env.REACT_APP_NEW_BASEURL}UserConsent/list/filter?status=${payload.status}&userId=${payload.userId}`
    return http.get(url);
  }
  updateUserConsent(payload){
    const url = `${process.env.REACT_APP_NEW_BASEURL}PatientConsent/addPatientConsent`
    return http.post(url,payload);
  }
}
export default new profileServices();
