import React, { useEffect, useState , useRef} from "react";
import { TabMenu } from "primereact/tabmenu";
import Divider from "@mui/material/Divider";
import { TabView, TabPanel } from "primereact/tabview";
import { Sidebar } from "primereact/sidebar";
import notificationScreens from "../../services/profileScreen/notificationScreens";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { toast } from "react-toastify";
import { Paginator } from "primereact/paginator";
import PatientSearch from "../Shared/patientSearch";
import search from "../../assets/images/search.svg";
import Loader from "../Loaders/Loader";
import { Toast } from "primereact/toast";

export default function Email(props) {
  const [sentMail, setsentMail] = useState([""]);
  const [getMail, setgetMail] = useState([""]);
  const [liveTime, setLiveTime] = useState("");
  const [subject, setsubject] = useState();
  const [body, setbody] = useState();
  const [patientSearch, setpatientSearch] = useState(false);
  const [emailData, setemailData] = useState("");
  const [loading, setloading] = useState(false);
  const toast = useRef(null);


  //pagination For getmail
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 5;

  //pagination for sent mail

  const [currentPagesent, setCurrentPagesent] = useState(1);
  const [totalPagessent, setTotalPagessent] = useState(0);

  const { show, setShow } = props;
  const userObj = JSON.parse(localStorage.getItem("loginObj"));
  console.log(userObj);

  const togglePatientSearchbar = () => {
    setpatientSearch(!patientSearch);
  };

  const onPageChange = (event) => {
    const newPage = event.page + 1;
    setCurrentPage(newPage);
  };

  const handleSelectedData = (selectedData) => {
    console.log("Inside handle");
    console.log("Retrievd data", selectedData);
    setemailData(selectedData);
  };

  const onPageChangeNew = (event) => {
    const newPage = event.page + 1;
    setCurrentPagesent(newPage);
  };
  useEffect(() => {
    console.log(currentPage);
  }, [currentPage]);

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };
  const showSuccess = (message) => {
    toast.current.show({severity:'success', summary: 'Success', detail:message, life: 3000});
}
  //For get mail

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, getMail.length);
  const getMailData = getMail.slice(startIndex, endIndex);

  //For sent Mail
  const startIndexsent = (currentPagesent - 1) * pageSize;
  const endIndexsent = Math.min(startIndexsent + pageSize, sentMail.length);
  const sentMailData = sentMail.slice(startIndexsent, endIndexsent);

  const testParam = userObj.user?.code;
  const emailId = userObj?.user?.email;
  const toName =
    userObj?.user?.salutation +
    " " +
    userObj?.user?.firstName +
    " " +
    userObj?.user?.lastName;

  const emailSentAPI = async () => {
    notificationScreens.emailSentAPI(emailId).then((res) => {
      console.log(res?.data, "EmailIDAPI");
      setsentMail(res?.data);
      setTotalPages(Math.ceil(res?.data.length / pageSize));
    });
  };
  const emailGetAPIs = async () => {
    notificationScreens.getEmailMailbycode(testParam).then((res) => {
      console.log(res?.data, "Email");
      setgetMail(res?.data);
      setTotalPagessent(Math.ceil(res?.data.length / pageSize));
    });
  };
  useEffect(() => {
    emailSentAPI();
    emailGetAPIs();
  }, []);

  // useEffect(() => {
  //   console.log(sentMail, getMail, "Mails");
  // }, []);

  const getCurrentTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const date = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    const formattedTime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
    setLiveTime(formattedTime);
  };

  useEffect(() => {
    const timerID = setInterval(() => getCurrentTime(), 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  const sendMail = () => {
    console.log("Inside Send Mail");
    const payload = {
      createdBy: emailId,
      createdDate: liveTime,
      fromDate: liveTime,
      modifiedBy: emailId,
      modifiedDate: liveTime,
      status: "1",
      subject: subject,
      content: body,
      toCode: emailData?.code,
      toName: emailData?.name,
      patientName: emailData?.name,
      fromCode: emailId,
      fromName: toName,
      trigeredUsertype: "P",
    };
    console.log(payload, "Payload");
    notificationScreens.sendmail(payload).then((res) => {
      console.log(res?.data, "POST API RESULT");
      if (res?.data?.[0] === 1) {
        setsubject(null);
        setbody(null);
        setShow(false);
        setemailData(null);
        showSuccess("Mail Sent Successfully");
        emailSentAPI();
      }
    }).catch((error) => {
      
      let errorMessage = "Error sending mail"; 
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        errorMessage = errorData.message; 
        if (errorData.details && errorData.details.length > 1 && errorData.details[1]) {
          errorMessage += `: ${errorData.details[1]}`;
        }
      }
    
      showError(errorMessage); // Display the constructed error message
    });
    
  };
  return (
    <>
     <Toast ref={toast} />
      <Sidebar
        visible={show}
        position="right"
        onHide={() => setShow(false)}
        className="w-[500px]"
      >
        <h2 className="font-bold text-2xl leading-6 tracking-normal text-left text-[#262626]">
          Emails
        </h2>
       

        <TabView className="mt-10">
          <TabPanel header="Received" className="mr-4">
            {getMailData.length > 0 &&
              getMailData.map((data, index) => (
                <div
                  className="w-80 h-28 rounded-xl bg-white border border-gray-300 mb-4 overflow-hidden"
                  key={index}
                >
                  <div className="flex flex-row items-center h-full">
                    <div className="flex flex-col ml-4">
                      <h1 className="font-bold mb-3 mt-7 truncate ">
                        {data?.toName}
                      </h1>
                      <h1 className="text-sm whitespace-normal overflow-ellipsis overflow-hidden">
                        {data?.content}
                      </h1>
                      <Divider style={{ marginTop: "10px" }} />
                      <h1 className="text-sm mt-4 pi pi-calendar mb-10">
                        {data?.createdDate && data.createdDate.substring(0, 10)}
                      </h1>
                    </div>
                  </div>
                </div>
              ))}
            <Paginator
              first={(currentPage - 1) * pageSize}
              rows={pageSize}
              totalRecords={getMail.length}
              onPageChange={onPageChange}
            />
          </TabPanel>
          <TabPanel header="Sent" className="mr-4">
            {sentMailData.length > 0 &&
              sentMailData.map((data, index) => (
                <div
                  className="w-80 h-28 rounded-xl bg-white border border-gray-300 mb-4 overflow-hidden"
                  key={index}
                >
                  <div className="flex flex-row items-center h-full">
                    <div className="flex flex-col ml-4">
                      <h1 className="font-bold mb-3  truncate mt-7">
                        {data?.toName}
                      </h1>
                      <h1 className="text-sm whitespace-normal overflow-ellipsis overflow-hidden">
                        {data?.content}
                      </h1>
                      <Divider style={{ marginTop: "10px" }} />
                      <h1 className="text-sm mt-4 pi pi-calendar mb-10">
                        {data?.createdDate && data.createdDate.substring(0, 10)}
                      </h1>
                    </div>
                  </div>
                </div>
              ))}
            <Paginator
              first={(currentPagesent - 1) * pageSize}
              rows={pageSize}
              totalRecords={sentMail.length}
              onPageChange={onPageChangeNew}
            />
          </TabPanel>
          <TabPanel header="New Mail" className="mr-4">
            <div className="flex flex-col">
              <label
                htmlFor="nameInput"
                className="block text-sm font-medium text-black font-medium mb-4"
              >
                From
              </label>
              <InputText
                value={toName}
                disabled
                placeholder="From...."
                // onChange={(e) => setValue(e.target.value)}
                className="border rounded border-solid border-[#E2E2E2] box-border w-full pl-3 pr-10"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="nameInput"
                className="block text-sm font-medium text-black font-medium mb-4 mt-8"
              >
                To
              </label>
              <div className="relative w-full">
                <InputText
                  placeholder="To...."
                  value={emailData?.name}
                  className="border rounded border-solid border-[#E2E2E2] box-border w-full pl-3 pr-10"
                  onClick={() => {
                    togglePatientSearchbar();
                  }}
                />
                <img
                  src={search}
                  alt=""
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 cursor-pointer"
                  onClick={() => {
                    togglePatientSearchbar();
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="nameInput"
                className="block text-sm font-medium text-black font-medium mb-4 mt-8"
              >
                Subject
              </label>
              <InputText
                value={subject}
                placeholder="Subject...."
                onChange={(e) => setsubject(e.target.value)}
                className="border rounded border-solid border-[#E2E2E2] box-border w-full pl-3 pr-10"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="nameInput"
                className="block text-sm font-medium text-black font-medium mb-4 mt-8"
              >
                Message
              </label>
              <InputTextarea
                value={body}
                placeholder="Type Here ......"
                rows={10}
                cols={30}
                onChange={(e) => setbody(e.target.value)}
                className="border rounded border-solid border-[#E2E2E2] box-border w-full pl-3 pr-10"
              />
            </div>
            <button
              className="w-64 h-10 border border-[#004172] bg-[#004172] text-[#FFFFFF] first-letter:font-bold font-monospace  rounded-md mt-6 ml-20"
              onClick={sendMail}
            >
              Send Mail
            </button>
          </TabPanel>
        </TabView>
      </Sidebar>
      <PatientSearch
        showbar={patientSearch}
        setshowBar={togglePatientSearchbar}
        onSelect={handleSelectedData}
      />
    </>
  );
}
