import React, { useEffect, useState } from 'react';
import {

  Typography,

} from '@mui/material';
import { Dropdown } from "primereact/dropdown";
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import apiService from '../../services/apiservice';
import { toast } from "react-toastify";
import { MultiSelect } from 'primereact/multiselect';
import moment from 'moment';
import { InputTextarea } from 'primereact/inputtextarea';
import { FaEye } from 'react-icons/fa';
import { FaUpload } from 'react-icons/fa';


const AddEnquiry = (props) => {
  const userObj = JSON.parse(localStorage.getItem('loginObj'))
  const roleCode = localStorage.getItem('userRole')
  const [formStatus, setFormStatus] = useState((props.selected.billingStatus == 2 || props.selected.status == 11) || (roleCode == 'ACCADMIN') || props.selected.status == 0 ? false : true)
  const [holdStatus, setholdStatus] = useState((props.selected.status == 0 || props.selected.status == 7) ? true : false);
  const [conStatus, setconStatus] = useState(props.selected.conciergeStatus == 4 ? true : false)
  const [approve, setApprove] = useState(false)





  const formatDate = (date) => {
    if (!date) return "";

    const d = new Date(date);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  };
  const mode = 'View'
  const [procedureList, setprocedureList] = useState([])
  const [docList, setDoclist] = useState([])
  const [doctype, selectedDoctype] = useState('')
  const [selectedDoc, setselectedDoc] = useState([])
  const [consentType, setconsentType] = useState()
  const [consentBlob, setConsentblob] = useState()
  const [dischargeBlob, setdischargeBlob] = useState()

  const [consentBase64, setconsentBase64] = useState()
  const [disChargeBase64, setdischargeBase64] = useState()
  const [dischargeType, setdischargeType] = useState()

  const [selectedRow, setselectedRow] = useState(props.selected ? props.selected : {});

  const [statusDs, setstatusDs] = useState([{ id: 0, value: 'Rejected' },
  { id: 1, value: 'Under Process' },

  { id: 4, value: 'Patient Denied' },
  { id: 5, value: 'Hospital Denied' },
  { id: 6, value: 'Procedure Confirmed' },
  { id: 7, value: 'Hold' },

  ])
  const billingStatusList = [
    { id: 1, value: 'Billing Uploaded' },

    { id: 2, value: 'Billing Done' },
    { id: 3, value: 'Billing Completed' },
  ]
  const conceirgStatusList = [
    { id: 1, value: 'Patient Admitted' },
    { id: 2, value: 'Patient Denied' },
    { id: 3, value: 'Patient iN OT' },
    { id: 4, value: 'Patient Discharged' },


  ]
  const HospitalizatioList = [
    { value: 1, name: 'General Surgery' },
    { value: 2, name: 'Oncology' },
    { value: 3, name: 'Neurology' },
    { value: 4, name: 'Orthopedic' },
    { value: 5, name: 'Urology' },
    { value: 6, name: 'Cardiology' },
    { value: 7, name: 'ENT' },
    { value: 8, name: 'Gynecology' },
    // { value: 9, name: 'Gastrologic' },
    { value: 10, name: 'Opthalmology' },
    { value: 11, name: 'General Medicine' },
    { value: 15, name: 'Nephrology' },
  ]

  const hospitalizatioList1 = [
    { value: 12, name: 'OPD Others' },
  ]
  const HospitalizatioList2 = [
    { value: 13, name: 'Investigation Others' },
  ]
  const HospitalizatioList3 = [
    { value: 14, name: 'Day Care Others' },
  ]
  const subServiceList = [
    { value: 1, name: 'Emergency' },
    { value: 2, name: 'Non-Emergency' },
    { value: 3, name: 'Others' },

  ]
  const [hospitalList, sethospitalList] = useState([])
  const [ceUserList, setceUserList] = useState([])

  const typeArray = [{ description: 'Empanel SP', code: 'SP' },
  { description: 'Empanel GP', code: 'GP' },
  { description: 'Inventory SP', code: 'ISP' },
  { description: 'Inventory GP', code: 'IGP' },
  { code: 'CD', description: 'Inventory Elite' },];
  props.selected.procedurePlannedEndDate = formatDate(props.selected.procedurePlannedEndDate)
  props.selected.procedurePlannedStartDate = formatDate(props.selected.procedurePlannedStartDate)
  props.selected.procedurePlannedDate = formatDate(props.selected.procedurePlannedDate)


  console.log(selectedRow)

  useEffect(() => {
    // if(props.selected.billingStatus == 2 || props.selected.status == 11){
    //   setFormStatus(false)
    // }
    // getProcedures();
    getHospitaliztionList()
    getHospitalList()
    getConciergeList()
    if (selectedRow.status == 12) {
      setstatusDs([
        { id: 0, value: 'Rejected' },
        { id: 1, value: 'Under Process' },

        { id: 4, value: 'Patient Denied' },
        { id: 5, value: 'Hospital Denied' },
        { id: 6, value: 'Procedure Confirmed' },
        { id: 12, value: 'Waiting For Approval' },

        { id: 7, value: 'Hold' }
      ])
    }
    else if (selectedRow.status == 6) {
      setstatusDs([
        { id: 0, value: 'Rejected' },
        { id: 1, value: 'Under Process' },
        // { id: 2, value: 'Discussing with Hospital' },
        // { id: 3, value: 'Discussing with Patient' },
        { id: 4, value: 'Patient Denied' },
        { id: 5, value: 'Hospital Denied' },
        { id: 7, value: 'Hold' },
        { id: 6, value: 'Procedure Confirmed' }

      ])
    }
    // else if ((selectedRow.status != 1 || selectedRow.status != 5) && selectedRow.status != 6) {
    //   setstatusDs([
    //     { id: 0, value: 'Rejected' },
    //     { id: 1, value: 'Under Process' },
    //     // { id: 2, value: 'Discussing with Hospital' },
    //     // { id: 3, value: 'Discussing with Patient' },
    //     { id: 4, value: 'Patient Denied' },
    //     { id: 5, value: 'Hospital Denied' },
    //     // { id: 6, value: 'Procedure Confirmed' },
    //     { id: 7, value: 'Hold' },

    //   ])
    // }
    else if (selectedRow.status == 5) {
      setstatusDs([
        { id: 1, value: 'Under Process' },
        { id: 5, value: 'Hospital Denied' }


      ])
    }
    else if (selectedRow.status == 11) {
      setstatusDs([
        { id: 11, value: 'New' },
      ])



    }
    setTimeout(() => {
      setselectedRow(props.selected ? props.selected : {})
    }, 1000);
  }, []);
  // const getProcedures = async () => {
  //   let res = await apiService.getprocedureList()
  //   setprocedureList(res.data)
  // }

  const convertUTCToLocal = (date, format) => {
    format = format || 'MM/DD/YYYY'; // YYYY-MM-DD hh:mm:ss
    const localTime = moment.utc(date).toDate();
    return moment(localTime).format(format);
  }
  const uploadBill = async (e, type) => {
    if (e.target.files[0].size > 1024000) {
      toast("please reduce the file size to below 1mb and upload");
      return;
    }
    let b64File = await encodeBase64File(e.target.files[0]);
    if (type == 'C') {
      setConsentblob(URL.createObjectURL(e.target.files[0]));

      setconsentBase64(b64File);
      setconsentType(e.target.files[0].type.split("/")[1]);
    }
    else {
      setdischargeBlob(URL.createObjectURL(e.target.files[0]));

      setdischargeBase64(b64File)
      setdischargeType(e.target.files[0].type.split("/")[1])
    }
    e.target.value = null;

  };

  const getHospitalList = async () => {
    let payload = {
      status: 1,
      HospitalType: 'H'
    }
    let res = await apiService.getHospitalList(payload)
    console.log(res)
    res.data.forEach(element => {
      element["hospitalLocationNames"] =
      element.hospitalName + " (" + element.name + ")";
  
    });
    sethospitalList(res.data)

  }
  const serviceTypeDs = [
    { id: 8, value: 'IPD' },
    { id: 9, value: 'OPD' },
    { id: 10, value: 'Investigation' },
    { id: 11, value: 'Day Care ' },

  ]
  const handleChane = (e) => {
    if (e.target?.id != 'date') {
      if (e.target.name == "conciergeStatus" && selectedRow.status != 6 && e.target.value == 4) {
        return toast.error("Procedure not yet confirmed")
      }
      if (e.target.name == 'status' && roleCode == 'CE' && (e.target.value == 1 || e.target.value == 6 || e.target.value == 12)) {
        return false
      }
      setselectedRow({
        ...selectedRow,
        [e.target.name]: e.target.value,
      })
    }
    else {

      //  let date= formatDate(e.target.value)
      setselectedRow({
        ...selectedRow,
        [e.target.name]: formatDate(e.target.value),
      });
    }

    // setselectedRow(select)
    if (e.target.name == 'hospitalizationTypeId') {
      getHospitaliztionList(e.target.value)
    }

  }
  const encodeBase64File = (file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        console.log(baseURL.split(',')[1]);
        resolve(baseURL.split(',')[1]);
      };
      console.log(fileInfo);
    });
  }
  const updateEnqList = async () => {
    if (!selectedRow.patientId) {
      return toast.error("Please select Patient");
    } else if (!selectedRow.address1) {
      return toast.error("Please select Address");
    } else if (!selectedRow.preferredHospitalAndClinics) {
      return toast.error("Please Select Hospital and Clinics");
    } else if (!selectedRow.serviceType) {
      return toast.error("Please select Service Type");
    } else if (!selectedRow.procedurePlannedStartDate) {
      return toast.error("Please Select  Planned Start Date");
    } else if (!selectedRow.procedurePlannedEndDate) {
      return toast.error("Please Select  Planned End Date");
    }
    //  else if (selectedRow.nationality == 'Others' && !selectedRow.passportNumber) {
    //   return toast.error("Please Enter Passport Number");
    // } 
    else if ((!selectedRow.procedurePlannedDate || selectedRow.procedurePlannedDate == 'NaN/NaN/NaN' || selectedRow?.procedurePlannedDate == 'Invalid date') && (selectedRow.status != 4 && selectedRow.status != 5 && selectedRow.status != 0 && selectedRow.status != 7)) {
      return toast.error("Please Select Procedure Confirmed Date");
    }
    // else if (!selectedRow.conciergeUserCode && mode != 'View') {
    //   return toast.error("Please Select Conceirge Executive.");
    // }
    else if (selectedRow.status == 7 && !selectedRow.reasonForHold) {
      return toast.error("Please Enter Reason for Hold");
    }
    else if (selectedRow.status == 0 && !selectedRow.reasonForReject) {
      return toast.error("Please Enter Reason for Reject");
    }
    else if (selectedRow.procedureId && +selectedRow.procedureId > 79 && !selectedRow.procedureNameDescription) {
      return toast.error("Please Enter Procedure NameDescription");
    }
    try {
      addAllDoctors()
      console.log(selectedRow.procedurePlannedDate)
      let payload = {...selectedRow}

      payload.modifiedBy = userObj.user.code
      payload.billingStatus = selectedRow.billingStatus

      payload.publishStatus = payload.status == 1 ? null : selectedRow.publishStatus
      payload.procedurePlannedDate = (!selectedRow.procedurePlannedDate || selectedRow.procedurePlannedDate == 'NaN/NaN/NaN' || selectedRow?.procedurePlannedDate == 'Invalid date') ? null : convertUTCToLocal(new Date(payload.procedurePlannedDate), 'YYYY-MM-DD hh:mm:ss');
      payload.procedurePlannedEndDate = convertUTCToLocal(new Date(payload.procedurePlannedEndDate), 'YYYY-MM-DD hh:mm:ss');
      payload.procedurePlannedStartDate = convertUTCToLocal(new Date(payload.procedurePlannedStartDate), 'YYYY-MM-DD hh:mm:ss');

      if (packageDoctorTypeList.length) {
        payload.enquiryAppointmentFromDate = payload.enquiryAppointmentFromDate ? this.utillService.convertUTCToLocal(new Date(payload.enquiryAppointmentFromDate), 'MM/DD/YYYY') : ''
        payload.serviceTaken = 'N'
        payload.enquiryAppointmentToDate = payload.enquiryAppointmentFromDate ? this.utillService.convertUTCToLocal(new Date(payload.enquiryAppointmentToDate), 'MM/DD/YYYY') : ''
        payload.patientServiceEnquiryDoctorsNewList = packageDoctorTypeList
      }
      if (consentBase64) {
        payload.surgeryConsent = consentBase64
        payload.surgeryConsentType = consentType
      }
      if (disChargeBase64) {
        payload.dischargeSummary = disChargeBase64
        payload.dischargeSummaryType = dischargeType
      }
      let payloa1 = []
      payloa1.push(payload)
      const res = await apiService.updateEnqList(payloa1);
      if (!res.message) {
        if(approve == false){
        props.onClose()}
        return toast.success('Enquiry updated successfully');

      }
    } catch (error) {

      console.error('Error fetching enquiry list:', error);

    }
  };
  const parseDate = (dateString) => {
    if (!dateString) return;
    const [month, day, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  };

  const getApproval = async () => {
    setApprove(true)
    if (!selectedRow.patientId) {
      return toast.error("Please select Patient");
    } else if (!selectedRow.address1) {
      return toast.error("Please select Address");
    } else if (!selectedRow.preferredHospitalAndClinics) {
      return toast.error("Please Select Hospital and Clinics");
    } else if (!selectedRow.serviceType) {
      return toast.error("Please select Service Type");
    } else if (!selectedRow.procedurePlannedStartDate) {
      return toast.error("Please Select  Planned Start Date");
    } else if (!selectedRow.procedurePlannedEndDate) {
      return toast.error("Please Select  Planned End Date");
    }
    //  else if (selectedRow.nationality == 'Others' && !selectedRow.passportNumber) {
    //   return toast.error("Please Enter Passport Number");
    // } 
    else if ((!selectedRow.procedurePlannedDate || selectedRow.procedurePlannedDate == 'NaN/NaN/NaN' || selectedRow?.procedurePlannedDate == 'Invalid date') && (selectedRow.status != 4 && selectedRow.status != 5 && selectedRow.status != 0 && selectedRow.status != 7)) {
      return toast.error("Please Select Procedure Confirmed Date");
    }
    // else if (!selectedRow.conciergeUserCode) {
    //   return toast.error("Please Select Conceirge Executive.");
    // }
    else if (selectedRow.status == 7 && !selectedRow.reasonForHold) {
      return toast.error("Please Enter Reason for Hold");
    }
    else if (selectedRow.status == 0 && !selectedRow.reasonForReject) {
      return toast.error("Please Enter Reason for Reject");
    }
    await updateEnqList()
    if (!selectedRow?.procedurePlannedDate || selectedRow?.procedurePlannedDate == 'Invalid date' || selectedRow?.procedurePlannedDate == 'NaN/NaN/NaN') {
      return
    }
    try {

      let payload = selectedRow
      payload.modifiedBy = userObj.user.code
      payload.procedurePlannedDate = convertUTCToLocal(new Date(payload.procedurePlannedDate), 'YYYY-MM-DD hh:mm:ss');
      payload.procedurePlannedEndDate = convertUTCToLocal(new Date(payload.procedurePlannedEndDate), 'YYYY-MM-DD hh:mm:ss');
      payload.procedurePlannedStartDate = convertUTCToLocal(new Date(payload.procedurePlannedStartDate), 'YYYY-MM-DD hh:mm:ss');
      // payload={
      payload.publishStatus = "W"
      // { id: 1, value: 'Surgery' },
      // payload.surgeryType = selectedRow.serviceType == 1 ? 'Surgery' : selectedRow.serviceType == 2 ? 'In-patient admission' : selectedRow.serviceType == 3 ? 'Procedures' : selectedRow.serviceType == 4 ? 'Maternity Package (Labour/Delivery/Recovery)' :
      //   selectedRow.serviceType == 5 ? 'Transplant' : selectedRow.serviceType == 6 ? 'Cosmetic / Plastic Procedures' : selectedRow.serviceType == 7 ? 'Cosmetic / Plastic Surgery' : '';


      payload.surgeryType = selectedRow.serviceType == 8 ? 'IPD' : selectedRow.serviceType == 9 ? 'OPD' : selectedRow.serviceType == 10 ? 'Investigation' : selectedRow.serviceType == 11 ? 'Day Care' :
        '';

      payload.userMobile = selectedRow.userMobile
      payload.userEmail = selectedRow.userEmail
      payload.userName = selectedRow.userName
      // }
      const res = await apiService.getApproval(payload, selectedRow.id);
      if (!res.message) {
        toast.success('Request sent Successfully')
        try {
          selectedRow.activeServiceStatus = 'Y'
          selectedRow.status = 12
          // enqObj.procedureDate = convertUTCToLocal(new Date(enqObj.procedureDate), 'YYYY-MM-DD hh:mm:ss')
          // enqObj.toDate = enqObj.toDate ? convertUTCToLocal(new Date(enqObj.toDate), 'YYYY-MM-DD hh:mm:ss') : ''
          delete selectedRow.patientServiceEnquiryFormStatusLogList
          delete selectedRow.pagination
          selectedRow.procedurePlannedDate = convertUTCToLocal(new Date(selectedRow.procedurePlannedDate), 'YYYY-MM-DD hh:mm:ss');
          selectedRow.procedurePlannedEndDate = convertUTCToLocal(new Date(selectedRow.procedurePlannedEndDate), 'YYYY-MM-DD hh:mm:ss');
          selectedRow.procedurePlannedStartDate = convertUTCToLocal(new Date(selectedRow.procedurePlannedStartDate), 'YYYY-MM-DD hh:mm:ss');
          let payload = []
          payload.push(selectedRow)
          const res1 = await apiService.updateEnqList(payload);
          console.log(res1)
          if (!res1.message) {
            props.onClose()

          }

        } catch (error) {
          console.error('Error fetching enquiry list:', error);
        }
      }
    } catch (error) {
      console.error('Error fetching enquiry list:', error);
    }


  }
  let empanelSplist = []
  let empanelGplist = []
  let inventoryGplist = []
  let inventorySplist = []
  let eliteList = []
  let packageDoctorTypeList = []
  const addlistDoctor = () => {
    empanelSplist = []
    empanelGplist = []
    inventoryGplist = []
    inventorySplist = []
    eliteList = []
    for (let s = 0; s < selectedDoc.length; s++) {
      let service
      service = {
        code: selectedDoc[s],
        docName: docList.find(x => x.code == selectedDoc[s]).userName
      }
      if (doctype == 'SP') {
        empanelSplist.push(service)
      }
      if (doctype == 'GP') {
        empanelGplist.push(service)
      }
      if (doctype == 'IGP') {
        inventoryGplist.push(service)
      }
      if (doctype == 'ISP') {
        inventorySplist.push(service)
      }
      if (doctype == 'CD') {
        eliteList.push(service)
      }

    }
  }
  const handleCancel = () => {
    props.onClose()
  }
  const trueDoctors = async (e) => {
    setselectedDoc([])
    selectedDoctype(e.target.value)
    let payload
    payload = {
      userRoleCode: "PROV,SPECIALIST",
      status: 1,
      uniqueUser: 'Y'
    }
    if (doctype == 'GP') {
      payload.userQuickConsulted = 1
      payload.specialityCode = 's36'
    }
    if (doctype == 'SP') {
      payload.userQuickConsulted = 1
      payload.specialityCode = 's1001'
    }
    if (doctype == 'IGP') {
      payload.userQuickConsulted = 0
      payload.specialityCode = 's36'

    }
    if (doctype == 'ISP') {
      payload.userQuickConsulted = 0
      payload.specialityCode = 's1001'

    }
    if (doctype == 'CD') {
      payload.userCurebayStarDoctor = 1
    }
    let res = await apiService.getDoctorList(payload)

    setDoclist(res.data)
  }
  const getHospitaliztionList = async (value) => {
    let payload = {
      serviceType: selectedRow.serviceType,
      hospitalizationTypeId: value ? value : selectedRow.hospitalizationTypeId
    }
    let res = await apiService.getHospitaliztionList(payload)
    if (res?.data?.data.length) {
      setprocedureList(res.data.data)
    }
    else {
      setprocedureList([]);
    }
  }
  const getConciergeList = async () => {
    let payload = {
      roleCode: "CE",
      status: 1,
    }
    let res = await apiService.getUserList(payload)
    setceUserList(res.data)
  }
  const addAllDoctors = () => {

    if (empanelSplist.length != 0) {
      for (let s = 0; s < empanelSplist.length; s++) {
        var data
        data = {
          "enquiryDoctorType": "EMPANEL SP",
          "status": 1,

        }

        data.enquiryUserName = empanelSplist[s].docName
        data.enquiryUserId = empanelSplist[s].code

        packageDoctorTypeList.push(data)
      }
    }
    if (empanelGplist.length != 0) {
      for (let p = 0; p < empanelGplist.length; p++) {
        var tino
        tino = {

          "enquiryDoctorType": "EMPANEL GP",
          "status": 1,

        }

        tino.enquiryUserName = empanelGplist[p].docName
        tino.enquiryUserId = empanelGplist[p].code

        packageDoctorTypeList.push(tino)

      }
    }
    if (inventoryGplist.length != 0) {
      for (let q = 0; q < inventoryGplist.length; q++) {
        var lino
        lino = {
          "enquiryDoctorType": "INVENTORY GP",
          "status": 1,

        }

        lino.enquiryUserName = inventoryGplist[q].docName
        lino.enquiryUserId = inventoryGplist[q].code
        packageDoctorTypeList.push(lino)

      }
    }
    if (inventorySplist.length != 0) {
      for (let y = 0; y < inventorySplist.length; y++) {
        var data2
        data2 = {
          "enquiryDoctorType": "INVENTORY SP",
          "status": 1,

        }

        data2.enquiryUserName = inventorySplist[y].docName
        data2.enquiryUserId = inventorySplist[y].code
        packageDoctorTypeList.push(data2)

      }
    }

    eliteList.forEach(element => {
      var data3
      data3 = {
        "enquiryDoctorType": "INVENTORY ELITE",
        "status": 1,

      }
      data3.enquiryUserName = element.docName
      data3.enquiryUserId = element.code
      packageDoctorTypeList.push(data3)


    }

    )
  }
  useEffect(() => {
    if (selectedRow && selectedRow.status == 11) {
      statusUpdate()
    }
  }, [])
  const statusUpdate = async () => {
    const payload = {
      "viewStatus": "1",
      "modifiedBy": userObj.user.code
    }
    let res = await apiService.viewStatusUpdate(payload, selectedRow.id)
  }
  return (
    <>
      <div style={{ opacity: formStatus == false ? 0.7 : 1, pointerEvents: formStatus == true ? 'auto' : 'none' }}>
        <div  >
          <div class="w-118 h-10 top-98 left-47  bg-lightBlue bg-opacity-5.5 overflow-auto" >
            <Typography class="font-poppins text-base font-semibold leading-6 mt-2 ml-2 text-left">Personal Details</Typography>
          </div>
          <div className='mt-6'>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label htmlFor="name">Name<span className="text-red-500">*</span></label>
                <InputText disabled={mode == 'View'} id="name" name='name' value={selectedRow.name} className="border border-gray-300 rounded-md p-2 w-full"
                />
              </div>
              <div>
                <label htmlFor="dob">DOB<span className="text-red-500">*</span></label>
                <Calendar id="field2" value={parseDate(selectedRow?.dob)}
                  className="border border-gray-300 rounded-md p-2 w-full" disabled name='dob' dateFormat="dd/mm/yy" style={{ height: '40px' }} onChange={(e) => handleChane(e)} />
              </div>
              {/* Repeat the above div blocks for the other fields */}
              <div>
                <label htmlFor="emailId">Email Id<span className="text-red-500">*</span></label>
                <InputText value={selectedRow.emailId} onChange={(e) => handleChane(e)} disabled name="emailId" className="border border-gray-300 rounded-md p-2 w-full" />
              </div>
              <div>
                <label htmlFor="mobile">Mobile Number<span className="text-red-500">*</span></label>
                <InputText name="contactNumber" onChange={(e) => handleChane(e)} value={selectedRow.contactNumber} disabled className="border border-gray-300 rounded-md p-2 w-full" />
                {/* Repeat the above div blocks for the other fields */}
              </div>
              <div>
                <label htmlFor="mobile">For Communication<span className="text-red-500">*</span></label>
                <InputText name="communicationMobile" onChange={(e) => handleChane(e)} value={selectedRow.communicationMobile} disabled className="border border-gray-300 rounded-md p-2 w-full" />
                {/* Repeat the above div blocks for the other fields */}
              </div>
              <div>
                <label htmlFor="address">Address1<span className="text-red-500">*</span></label>
                <InputText name="address1" onChange={(e) => handleChane(e)} disabled={mode == 'View'} value={selectedRow.address1} className="border border-gray-300 rounded-md p-2 w-full" />
              </div>
              <div>
                <label htmlFor="address">Address2</label>
                <InputText name="address2" onChange={(e) => handleChane(e)} disabled={mode == 'View'} value={selectedRow.address2} className="border border-gray-300 rounded-md p-2 w-full" />
              </div>
              <div>
                <label htmlFor="address">City<span className="text-red-500">*</span></label>
                <InputText name="city" onChange={(e) => handleChane(e)} disabled value={selectedRow.city} className="border border-gray-300 rounded-md p-2 w-full" />
              </div>
              <div>
                <label htmlFor="address">State<span className="text-red-500">*</span></label>
                <InputText name="stateName" onChange={(e) => handleChane(e)} disabled value={selectedRow.stateName} className="border border-gray-300 rounded-md p-2 w-full" />
              </div>
              {/* <div>
              <label htmlFor="country">Country</label>
              <InputText name="country" onChange={(e) => handleChane(e)} disabled={mode == 'View'} value={selectedRow.country} className="border border-gray-300 rounded-md p-2 w-full" />
            </div> */}
              {/* <div>
          <label htmlFor="mobile">Country:</label>
          <InputText id="mobile" className="border border-gray-300 rounded-md p-2 w-full" />
        </div> */}
              {/* Repeat the above div blocks for the other fields */}
              {/* <div>
              <label htmlFor="passPort">Passport Number</label>
              <InputText name="passportNumber" disabled={mode == 'View'} onChange={(e) => handleChane(e)} value={selectedRow.passportNumber} className="border border-gray-300 rounded-md p-2 w-full" />
            </div> */}

            </div>

          </div>

        </div>
        <div>
          <div class="w-118 mt-7 h-10 top-98 left-47 bg-lightBlue overflow-auto">
            <Typography class="font-poppins text-base font-semibold leading-6 mt-2 ml-2 text-left">Insurance Details</Typography>
            <div>


            </div>
          </div>
          <div className='mt-6'>
            <div className="grid grid-cols-3 gap-4">

              <div>
                <label htmlFor="dob">Insurance Id:</label>
                <InputText name="insuranceId" value={selectedRow.insuranceId} onChange={(e) => handleChane(e)} className="border border-gray-300 rounded-md p-2 w-full" />

              </div>
              {/* Repeat the above div blocks for the other fields */}

              <div>
                <label htmlFor="emailId">Insurance Name:</label>
                <InputText id="emailId" value={selectedRow.insuranceName} onChange={(e) => handleChane(e)} className="border border-gray-300 rounded-md p-2 w-full" />
              </div>
              <div>
                <label htmlFor="mobile">Insurance Amount:</label>
                <InputText id="mobile" value={selectedRow.insuranceAmount} onChange={(e) => handleChane(e)} className="border border-gray-300 rounded-md p-2 w-full" />
              </div>
              <div>
                <label htmlFor="name">Preferred Hospital and Clinics<span className="text-red-500">*</span></label>
                {/* <InputText  value={selectedRow.hospitalName}  id="name" className="border border-gray-300 rounded-md p-2 w-full" /> */}
                <Dropdown
                  value={selectedRow?.hospitalId}
                  options={hospitalList}
                  optionLabel="hospitalLocationNames" // This tells Dropdown how to display the options
                  placeholder="Select a Hospital"
                  name='hospitalId'
                  optionValue='hospitalCode'
                  onChange={(e) => handleChane(e)}
                  className="border border-gray-300 rounded-md  w-full"
                  disabled
                />
              </div>
              {/* <div className='flex gap-3 ml-8'>
                <div className='flex gap-2'>


                  {selectedRow?.idProofName ? <a className="flex justify-items-center gap-2 border border-[#004172] text-[#004172]  h-10 font-bold rounded-md  p-3 mt-6 "
                    href={`${process.env.REACT_APP_IMAGE_URL + selectedRow.idProofName}`} target="_blanlk" disable={selectedRow?.idProofName == null || selectedRow?.idProofName == ''} >
                    ID Proof <FaEye className='text-[#004172] mt-1' />

                  </a> :
                    <a disable className="flex justify-items-center gap-2 border border-[#939697] text-[#939697] font-bold rounded-md h-10 p-3 mt-6 ">
                      ID Proof
                    </a>}
                </div>
                <div  >

                  {selectedRow?.medicalReportName ? <a className="flex justify-items-center gap-2 border border-[#004172] text-[#004172] h-10 font-bold rounded-md  p-3 mt-6 "
                    href={`${process.env.REACT_APP_IMAGE_URL + selectedRow.medicalReportName}`} target="_blanlk" disabled={selectedRow?.medicalReportName == null || selectedRow?.medicalReportName == ''}>
                    Medical Report    <FaEye className='text-[#004172] mt-1' />
                  </a> :
                    <a disable className="flex justify-items-center gap-2 border border-[#939697] text-[#939697] h-10 font-bold rounded-md  p-3 mt-6 ">
                      Medical Report
                    </a>}
                </div>
              </div> */}
            </div>


          </div>

        </div>
        <div>
          <div class="w-118 mt-7 h-10 top-98 left-47 bg-lightBlue overflow-auto" >
            <Typography class="font-poppins text-base font-semibold leading-6 mt-2 ml-2 text-left">Service Details</Typography>
            <div>


            </div>
          </div>
          <div className='mt-6'>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label htmlFor="name">Service Type<span className="text-red-500">*</span></label>
                {/* <InputText id="name"  value={selectedRow.serviceType}  className="border border-gray-300 rounded-md p-2 w-full" /> */}
                <Dropdown
                  value={+selectedRow?.serviceType}
                  options={serviceTypeDs}
                  optionLabel="value" // This tells Dropdown how to display the options
                  placeholder="Select a Procedure"
                  optionValue='id'
                  name='serviceType'
                  editable
                  className="border border-gray-300 rounded-md  w-full"
                  onChange={(e) => handleChane(e)} />
              </div>
              <div>
                <label htmlFor="name">Service Sub Type<span className="text-red-500">*</span></label>
                {/* <InputText  value={selectedRow.hospitalName}  id="name" className="border border-gray-300 rounded-md p-2 w-full" /> */}
                <Dropdown
                  value={+selectedRow?.serviceSubTypeId}

                  optionLabel="name" // This tells Dropdown how to display the options
                  placeholder="Select Service Sub Type"
                  options={subServiceList}
                  name='serviceSubTypeId' optionValue='value'
                  className="border border-gray-300 rounded-md w-full"
                  // disabled={mode == 'View'} 
                  onChange={(e) => handleChane(e)}
                />
              </div>

              <div>
                <label htmlFor="dob">Hospitalization Type<span className="text-red-500">*</span></label>
                <Dropdown
                  options={+selectedRow?.serviceType == 8 ? HospitalizatioList : +selectedRow?.serviceType == 9 ? hospitalizatioList1 : +selectedRow?.serviceType == 10 ? HospitalizatioList2 : +selectedRow?.serviceType == 11 ? HospitalizatioList3 : []}

                  optionLabel="name" // This tells Dropdown how to display the options
                  placeholder="Select Hospitalization Type"
                  name='hospitalizationTypeId' value={+selectedRow?.hospitalizationTypeId} optionValue='value'
                  onChange={(e) => handleChane(e)}
                  className="border border-gray-300 rounded-md  w-full"
                // disabled={mode == 'View'} 
                />
              </div>
              <div>
                <label >Hospitalization Sub Type</label>
                <Dropdown
                  value={+selectedRow?.procedureId}
                  options={procedureList}
                  optionLabel="hospitalizationSubType" // This tells Dropdown how to display the options
                  placeholder="Select a Procedure"
                  optionValue='hospitalizationSubTypeId'
                  onChange={(e) => handleChane(e)}
                  className="border border-gray-300 rounded-md w-full"
                  name='procedureId' />            </div>
              {(selectedRow.procedureId && +selectedRow.procedureId > 79) && <div>
                <label htmlFor="address">Hospitalization Subtype Description
                </label>
                <InputText name="procedureNameDescription" onChange={(e) => handleChane(e)} value={selectedRow.procedureNameDescription} className="border border-gray-300 rounded-md p-2 w-full" />
              </div>}
              <div>
                <label htmlFor="dob">Planned Start Date<span className="text-red-500">*</span></label>
                <Calendar name="procedurePlannedStartDate" disabled value={parseDate(selectedRow?.procedurePlannedStartDate)} id='date' onChange={(e) => handleChane(e)} className="border border-gray-300 rounded-md p-2 w-full" dateFormat="dd/mm/yy" style={{ height: '40px' }} />
              </div>
              {/* Repeat the above div blocks for the other fields */}

              <div>
                <label htmlFor="emailId">Planned End Date<span className="text-red-500">*</span></label>
                <Calendar name="procedurePlannedEndDate" disabled onChange={(e) => handleChane(e)} id='date' value={parseDate(selectedRow?.procedurePlannedEndDate)} className="border border-gray-300 rounded-md p-2 w-full" dateFormat="dd/mm/yy" style={{ height: '40px' }} />
              </div>
              {/* Repeat the above div blocks for the other fields */}
              <div>
                <label htmlFor="emailId">Planned Confirmed Date<span className="text-red-500">*</span></label>
                <Calendar name="procedurePlannedDate" onChange={(e) => handleChane(e)} id='date' value={parseDate(selectedRow?.procedurePlannedDate)} className="border border-gray-300 rounded-md p-2 w-full" dateFormat="dd/mm/yy" style={{ height: '40px' }} />
              </div>

              <div>
                <label htmlFor="country">Status</label>
                <Dropdown
                  value={selectedRow?.status}
                  options={statusDs}
                  optionLabel="value" // This tells Dropdown how to display the options
                  placeholder="Select a Status"
                  optionValue='id'
                  name='status'
                  editable
                  style={{ height: '40px', fontSize: selectedRow?.status === 6 ? 'small' : 'inherit' }}
                  className="border border-gray-300 rounded-md p-2 w-full"
                  onChange={(e) => handleChane(e)}
                  disabled={holdStatus == true} />             </div>

              {selectedRow.billingStatus != 0 && <div>
                <label htmlFor="country"> Billing Status</label>
                <Dropdown
                  value={selectedRow?.billingStatus}
                  options={billingStatusList}
                  optionLabel="value" // This tells Dropdown how to display the options
                  placeholder="Select a Billing Status"
                  optionValue='id'
                  name='billingStatus'
                  editable
                  className="border border-gray-300  text-sm rounded-md  w-full"
                  onChange={(e) => handleChane(e)} />             </div>}
              <div>
                <label htmlFor="address" style={{ fontSize: 'small' }}>Estimate Amount for Procedure
                </label>
                <InputText name="quotatedAmount" onChange={(e) => handleChane(e)} value={selectedRow.quotatedAmount} className="border border-gray-300 rounded-md p-2 w-full" />
              </div>
              <div>
                <label htmlFor="address">Do you have any other medical conditions?
                </label>
                <InputText name="notes1" onChange={(e) => handleChane(e)} value={selectedRow.notes1} className="border border-gray-300 rounded-md p-2 w-full" />
              </div>


              {selectedRow?.status == 7 && <div>
                <label htmlFor="address">Reason for Hold<span className="text-red-500">*</span>
                </label>
                <InputTextarea name="reasonForHold" onChange={(e) => handleChane(e)} value={selectedRow.reasonForHold} className="border border-gray-300 rounded-md p-2 w-full"  maxLength={1000}/>
              </div>}

              {selectedRow?.status == 0 && <div>
                <label htmlFor="address">Reason for Reject<span className="text-red-500">*</span>
                </label>
                <InputTextarea name="reasonForReject" onChange={(e) => handleChane(e)} value={selectedRow.reasonForReject} className="border border-gray-300 rounded-md p-2 w-full"  maxLength={1000} />
              </div>}
            </div>
            <div class="w-118    mt-7 h-10 top-98  left-47 bg-lightBlue overflow-x-hidden" >
              <div className=' flex justify-between  justify-items-center '>

                <Typography class="font-poppins text-base font-semibold leading-6 mt-2  ml-2 text-left ">Consent Details</Typography>
                {/* Button for uploading bill */}
                <div className='mt-2 mr-2'>

                  <label htmlFor="bill-upload" className=" flex justify-items-center gap-2 text-[#004172] font-bold ">
                    {/* block text-center w-full */}
                    <FaUpload className='text-[#004172] mt-1' />
                    Surgery Consent
                  </label>
                  <input
                    id="bill-upload"
                    type="file"
                    accept=".pdf, image/*" // Accept PDF files and images of any type
                    className="hidden  w-full "
                    onChange={e => uploadBill(e, 'C')}
                  />
                </div>
              </div>



            </div>

            <div>
              <div className='mt-6'>
                <div className="grid grid-cols-3 gap-4 justify-items-center">
                  <div>
                    <label htmlFor="name">Concerige Executive<span className="text-red-500">*</span></label>
                    {/* <InputText id="name"  value={selectedRow.serviceType}  className="border border-gray-300 rounded-md p-2 w-full" /> */}
                    <Dropdown
                      value={selectedRow?.conciergeUserCode}
                      options={ceUserList}
                      optionLabel="userName" // This tells Dropdown how to display the options
                      placeholder="Select Executive"
                      optionValue='code'
                      name='conciergeUserCode'
                      className="border border-gray-300  text-sm rounded-md  w-full"
                      onChange={(e) => handleChane(e)}
                      disabled
                    />
                  </div>
                  <div>
                    <label htmlFor="name">Concerige Status</label>
                    {/* <InputText id="name"  value={selectedRow.serviceType}  className="border border-gray-300 rounded-md p-2 w-full" /> */}
                    <Dropdown
                      value={selectedRow?.conciergeStatus}
                      options={conceirgStatusList}
                      optionLabel="value" // This tells Dropdown how to display the options
                      placeholder="Select a Concerige Status"
                      optionValue='id'
                      name='conciergeStatus'
                      editable
                      className="border border-gray-300  text-sm rounded-md  w-full"
                      onChange={(e) => handleChane(e)}
                      disabled={conStatus == true}

                    />
                  </div>

                  <div className=' flex justify-between  justify-items-center '>

                    {/* Button for uploading bill */}
                    {selectedRow?.conciergeStatus == 4 && <div className='mt-7 mr-2'>

                      <label htmlFor="summary" className=" flex justify-items-center gap-2 text-[#004172] font-bold ">
                        {/* block text-center w-full */}
                        <FaUpload className='text-[#004172] mt-1' />
                        Discharge Summary
                      </label>
                      <input
                        id="summary"
                        type="file"
                        accept=".pdf, image/*" // Accept PDF files and images of any type
                        className="hidden  w-full "
                        onChange={e => uploadBill(e, 'S')}
                      />
                    </div>}
                  </div>
                </div>

              </div>

              <div className='mt-6 flex  justify-between border-t border-b p-3'>
                {(selectedRow.surgeryConsentName || consentBlob) && <div className='flex justify-items-center gap-2'>
                  <label className='w-100 h-21'>View Surgery Consent</label>: <a href={consentBlob ? consentBlob : `${process.env.REACT_APP_IMAGE_URL + selectedRow.surgeryConsentName}`} target="_blank" ><FaEye className="text-[#6fb8ef] mt-1 " />
                  </a>
                </div>}
                {(selectedRow.dischargeSummaryName || dischargeBlob) && <div className='flex justify-items-center gap-2'>
                  <label className='w-100 h-21'> Discharge Summary</label>: <a href={dischargeBlob ? dischargeBlob : `${process.env.REACT_APP_IMAGE_URL + selectedRow.dischargeSummaryName}`} target="_blank" ><FaEye className="text-[#6fb8ef] mt-1 " />
                  </a>
                </div>}
                {selectedRow.idProofName && <div className='flex justify-items-center gap-2'>
                  <label className='w-100 h-21'>View Idproof</label>: <a href={`${process.env.REACT_APP_IMAGE_URL + selectedRow.idProofName}`} target="_blanlk" disabled={selectedRow?.idProofName == null || selectedRow?.idProofName == ''} ><FaEye className="text-[#6fb8ef] mt-1 " />
                  </a>
                </div>}
                {selectedRow.medicalReportName && <div className='flex justify-items-center gap-2'>
                  <label className='w-100 h-21'>View MedicalReport</label>: <a href={`${process.env.REACT_APP_IMAGE_URL + selectedRow.medicalReportName}`} target="_blanlk" disabled={selectedRow?.medicalReportName == null || selectedRow?.medicalReportName == ''} ><FaEye className="text-[#6fb8ef] mt-1 " />
                  </a>
                </div>}
              </div>


            </div>


            {/* <div>
          <label htmlFor="mobile">Country:</label>
          <InputText id="mobile" className="border border-gray-300 rounded-md p-2 w-full" />
        </div> */}
            {/* Repeat the above div blocks for the other fields */}
            <div className=" flex-last grid mt-10 grid-cols-2 gap-4">
              <div >
                <button disabled={selectedRow.billingStatus == 2 || selectedRow.status == 4 || selectedRow.status == 5 || selectedRow.status == 0 || selectedRow.status == 7 || selectedRow.status == 6} className="h-9  bg-[#004172] text-white rounded-md  w-[80%]"
                  onClick={getApproval} >
                  Get Approval from Hospital Admin
                </button>
              </div>
              <div className=' flex gap-4'>


                <button className=" h-9 border border-[#004172] text-[#004172] font-bold font-monospace  rounded-md  w-[80%]"
                  onClick={handleCancel}>
                  Cancel
                </button>


                <button className=" h-9  bg-[#004172] text-white rounded-md  w-[80%]"
                  onClick={updateEnqList}>
                  Save
                </button>
              </div>
            </div>


            <div className="grid grid-cols-1">


            </div>



            {/* <div>
          <label htmlFor="mobile">Country:</label>
          <InputText id="mobile" className="border border-gray-300 rounded-md p-2 w-full" />
        </div> */}
            {/* Repeat the above div blocks for the other fields */}

          </div>

          {selectedRow.serviceType == 1 && (
            <>
              <div class="w-118 mt-7 h-10 top-98 left-47 bg-lightBlue overflow-auto" >
                <Typography class="font-poppins text-base font-semibold leading-6 mt-2 ml-2 text-left">Book Free Appointments</Typography>
                <div>


                </div>
              </div>

              <div className='mt-6'>
                <div className="grid grid-cols-3 gap-4">
                  {/* typeArray */}
                  <div>
                    <label htmlFor="name">Doctor Type</label>
                    <Dropdown
                      options={typeArray}
                      optionLabel="description" // This tells Dropdown how to display the options
                      placeholder="Select a Status"
                      optionValue='code'
                      value={doctype}
                      name='status'
                      editable
                      style={{ height: '40px' }}
                      className="border border-gray-300 rounded-md p-2 w-full"
                      onChange={(e) => trueDoctors(e)}
                    />
                  </div>
                  <div>
                    <label htmlFor="dob">Select Doctors</label>
                    <MultiSelect
                      options={docList}
                      optionLabel="userName" // This tells Dropdown how to display the options
                      value={selectedDoc}
                      onChange={(e) => setselectedDoc(e.target.value)}

                      optionValue='code'
                      style={{ height: '40px' }}
                      className="border border-gray-300 rounded-md p-2 w-full"
                    />


                  </div>
                  {/* Repeat the above div blocks for the other fields */}
                  <div className='mr-10'>
                    <button onClick={addlistDoctor}
                      className="w-[50%] mt-4 h-12  bg-[#004172] text-white rounded-md " >
                      Add Doctors
                    </button>
                  </div>

                  {/* Repeat the above div blocks for the other fields */}
                </div>
                <div className="grid gap-4 grid-cols-3 mt-10 ">

                  {empanelSplist.length > 0 &&
                    <div > {/* Row 1 */}
                      <label htmlFor="name">Empanel SP</label>
                      <Dropdown
                        options={empanelSplist}
                        optionLabel="docName" // This tells Dropdown how to display the options
                        placeholder="Select a Status"
                        optionValue='code'
                        name='status'
                        editable
                        style={{ height: '40px' }}
                        className="border border-gray-300 rounded-md p-2 w-full"
                        onChange={(e) => trueDoctors(e)}
                      />
                    </div>}

                  {/* :<span></span>} */}


                  {empanelGplist.length > 0 &&
                    <div > {/* Row 1 */}
                      <label htmlFor="name">Empanel GP</label>
                      <Dropdown
                        options={empanelGplist}
                        optionLabel="docName" // This tells Dropdown how to display the options
                        optionValue='code'
                        name='status'
                        editable
                        style={{ height: '40px' }}
                        className="border border-gray-300 rounded-md p-2 w-full"
                        onChange={(e) => trueDoctors(e)}
                      />
                    </div>}
                  {/* )} */}
                  {inventorySplist.length > 0 &&
                    <div > {/* Row 1 */}
                      <label htmlFor="name">Inventory SP</label>
                      <Dropdown
                        options={inventorySplist}
                        optionLabel="docName" // This tells Dropdown how to display the options
                        optionValue='code'
                        name='status'
                        editable
                        style={{ height: '40px' }}
                        className="border border-gray-300 rounded-md p-2 w-full"
                        onChange={(e) => trueDoctors(e)}
                      />
                    </div>}
                  {inventoryGplist.length > 0 &&
                    <div > {/* Row 1 */}
                      <label htmlFor="name">Inventory GP</label>
                      <Dropdown
                        options={inventoryGplist}
                        optionLabel="docName" // This tells Dropdown how to display the options
                        optionValue='code'
                        name='status'
                        editable
                        style={{ height: '40px' }}
                        className="border border-gray-300 rounded-md p-2 w-full"
                        onChange={(e) => trueDoctors(e)}
                      />
                    </div>}
                  {eliteList.length > 0 &&
                    <div >
                      <label htmlFor="name">Inventory Elite</label>
                      <Dropdown
                        options={eliteList}
                        optionLabel="docName" // This tells Dropdown how to display the options
                        optionValue='code'
                        name='status'
                        editable
                        style={{ height: '40px' }}
                        className="border border-gray-300 rounded-md p-2 w-full"
                        onChange={(e) => trueDoctors(e)}
                      />
                    </div>}
                  <div>
                    <label htmlFor="emailId">From Date</label>
                    <Calendar name="enquiryAppointmentFromDate" onChange={(e) => handleChane(e)} id='date' value={parseDate(selectedRow?.enquiryAppointmentFromDate)} className="border border-gray-300 rounded-md p-2 w-full" dateFormat="dd/mm/yy" style={{ height: '40px' }} />
                  </div>
                  <div>
                    <label htmlFor="mobile">To Date</label>
                    <Calendar name="enquiryAppointmentToDate" onChange={(e) => handleChane(e)} id='date' value={parseDate(selectedRow?.enquiryAppointmentToDate)} className="border border-gray-300 rounded-md p-2 w-full" dateFormat="dd/mm/yy" style={{ height: '40px' }} />
                  </div>
                  {/* } */}
                </div>

              </div>

            </>
          )}

        </div>
      </div>
    </>

  )
}

export default AddEnquiry;