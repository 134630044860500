import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Modal,
  Button,
  Box,
  Pagination, Checkbox
} from '@mui/material';
import OurLayouts from '../Layouts';
import apiService from '../../services/apiservice';
import { FaEye, FaLaptopHouse, FaSearch } from 'react-icons/fa';
import { Sidebar } from "primereact/sidebar";
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { FaFilter } from 'react-icons/fa';
import { InputText } from 'primereact/inputtext';
import PatientSearch from '../Shared/patientSearch';
import { Dropdown } from "primereact/dropdown";
import { TiExport } from "react-icons/ti";
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';



const PracticeAdminBillList = (props) => {
  const userObj = JSON.parse(localStorage.getItem('loginObj'))
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setloading] = useState(false);
  const [billinglist, setbillingList] = useState([]);
  const [page1, setPage] = useState(1);
  const [selected, setSelected] = useState({});
  const [viewEnquiry, setViewEnquiry] = useState(false);
  const [filterObj, setfilterObj] = useState();
  const [patientSearch, setpatientSearch] = useState(false);
  const statusOptions = [
    { id: 4, value: 'Approved' },
    { id: 5, value: 'Rejected' },
  ]
  let page = 1
  const handleChangeTab = (tab) => {
    setfilterObj()
    setSelected({})
    setActiveTab(tab);
    setPage(1)
  }
  const handlePageChange = (event, value) => {
    page = value
    setPage(value)
    setbillingList([])
    setSelected({})
    getBillingList()
  };
  const getStylestatus = (e) => {
    let payload = {
      border: '1px solid', // Adds a border of 1px solid
      borderColor: (e == 1 || e == 2 || e == 3 || e == 7) ? '#ECAC09' : (e == 0 || e == 4 || e == 5) ? '#DD0000' : (e == 6 || e == 8 || e == 9) ? '#299F5D' : e == 11 ? '#3D3BA4' : 'black', // Sets the border color to match the text color
      padding: '5px',
      color: (e == 1 || e == 2 || e == 3 || e == 7) ? '#ECAC09' : (e == 0 || e == 4 || e == 5) ? '#DD0000' : (e == 6 || e == 8 || e == 9) ? '#299F5D' : e == 11 ? '#3D3BA4' : 'black',
      display: 'inline-block',
      borderRadius: '5px'
    }
    return payload
  }
  const getbillingStyle = (e) => {
    let payload = {

      color: e == 1 ? '#ECAC09' : e == 2 ? 'violet' : e == 3 ? '#7225EF' : e == 4 ? '#299F5D' : e == 5 ? '#DD0000' : '#3D3BA4',

    }
    return payload
  }
  const getBillingList = async (data) => {
    const res = await apiService.userInfo(userObj.user.code)
    let payload = {}
    try {
      payload.billingStatus = 3
      payload.pageNumber = page
      payload.hospitalId = res?.data[0]?.hospitalId;
      setloading(true)
 
      if (activeTab == 1) {
        if (filterObj && filterObj.billingStatus) {
          payload.billingStatus= filterObj.billingStatus
        }
        if (!filterObj?.billingStatus) {
          payload.roleCode = "PRADMIN";

        delete payload.billingStatus
      }
      }
      // if (activeTab == 1) {
      //   delete payload.billingStatus
      // }
      if (filterObj && filterObj.id) {
        payload.id = filterObj.id
      }
      if (filterObj && filterObj.patientId) {
        payload.patientId = filterObj.patientId
      }
      if (filterObj && filterObj.fromDate) {
        payload.fromDate = filterObj.fromDate
      }
      if (filterObj && filterObj.toDate) {
        payload.toDate = filterObj.toDate
      }
      const result = await apiService.getServicesBillList(payload)
      if (!result.message) {
        let data = []
        setloading(false)
        setbillingList(result.data)
      }
    } catch (error) {
      console.error('Error fetching enquiry list:', error);
      setloading(false)
    }
  };
  useEffect(() => {
    getBillingList();
  }, []);
  useEffect(() => {
    getBillingList();
  }, [activeTab]);
  const onAction = async (data, tab) => {
    let payload = [{
      modifiedBy: userObj.user.code,
      modifiedDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      billingStatus: tab == "A" ? 4 : 5,
      id: data.id,
      serviceEnquiryId: data.serviceEnquiryId,
      billingPublishStatus: tab

    }]
    const result = await apiService.billingApprovalUpdate(payload)
    if (result) {
      let message = tab == "A" ? "Approved successfully" : "Rejected successfully"
      toast(message)
      getBillingList();
    }
  }
  const handleCheckboxChange = (id) => {
    setSelected((prevSelected) => ({
      ...prevSelected,
      [id]: !prevSelected[id],
    }));
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    const newSelected = {};
    billinglist.forEach((notifi) => {
      newSelected[notifi.id] = isChecked;
    });
    setSelected(newSelected);
  };
  const onActionAll = async (tab) => {
    let selectedrow = billinglist.filter(item => selected[item.id]);
    if (selectedrow && selectedrow.length) {
      let payload = selectedrow.map(item => {
        return {
          modifiedBy: userObj.user.code,
          modifiedDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
          billingStatus: tab == "A" ? 4 : 5,
          id: item.id,
          serviceEnquiryId: item.serviceEnquiryId,
          billingPublishStatus: tab
        };
      });
      const result = await apiService.billingApprovalUpdate(payload)
      if (result) {
        let message = tab == "A" ? "Approved successfully" : "Rejected successfully"
        toast(message)
        getBillingList();
      }
    } else {
      return toast("Nothing selected")
    }
  }
  const handleClickFilter = (data, mode) => {
    setViewEnquiry(true)
  }
  const handleChangeFilter = (e) => {
    if (e.target?.id != 'date') {
      setfilterObj({
        ...filterObj,
        [e.target.name]: e.target.value,
      })
    } else {
      setfilterObj({
        ...filterObj,
        [e.target.name]: formatDate(e.target.value),
      });
    }
  }
  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [month, day, year].join("/");
  };
  const parseDate = (dateString) => {
    if (!dateString) return;
    const [month, day, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  };
  const toggleSidebarFilter = () => {
    setpatientSearch(!patientSearch);
  };
  const handleSelectedData = (e) => {
    let filter = {}
    filter = filterObj || {}
    filter.patientId = e.code
    filter.patientName = e.name
    setfilterObj(filter)
  }
  const filterService = () => {
    setViewEnquiry(false);
    getBillingList()
  }
  const handleCancel = () => {
    setfilterObj()
  }
  return (
    <>
      <OurLayouts>
        <Backdrop
          sx={{ color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress size={80} color="inherit" />
        </Backdrop>
        <Sidebar style={{ width: '45%', borderBottom: '1px solid #ccc' }} dismissable={false}
          visible={viewEnquiry} header={"Filter Service"} centered={true}
          onHide={() => setViewEnquiry(false)} position="right"
        >
          <div className="grid mt-4  grid-cols-2 gap-4 ">
            <div >
              <label htmlFor="dob">From Date</label>
              <Calendar id="date"
                className="border border-gray-300 rounded-md p-2 w-full" dateFormat="dd/mm/yy" style={{ height: '40px' }}
                onChange={(e) => handleChangeFilter(e)} name='fromDate' value={parseDate(filterObj?.fromDate)} placeholder="Select From Date"
              />
            </div>
            <div >
              <label htmlFor="dob">To Date </label>
              <Calendar id="date"
                className="border border-gray-300 rounded-md p-2 w-full" dateFormat="dd/mm/yy" style={{ height: '40px' }}
                onChange={(e) => handleChangeFilter(e)} name='toDate' value={parseDate(filterObj?.toDate)} placeholder="Select To Date"
              />
            </div>
            <div>
              <label htmlFor="id">Enquiry Id</label>
              <div className="relative">
                <InputText id="id" name="id" className="border border-gray-300 rounded-md p-2 pr-10 w-full"
                  onChange={(e) => handleChangeFilter(e)}
                  value={filterObj?.id ? filterObj.id : ""} placeholder="Search for Enquiry Id" />
              </div>
            </div>
            {activeTab == 1 &&
              <div>
                <label htmlFor="status">Status</label>
                <Dropdown
                  value={filterObj?.billingStatus ? filterObj?.billingStatus : ""}
                  options={statusOptions}
                  optionLabel="value" // This tells Dropdown how to display the options
                  placeholder="Select Status"
                  optionValue='id'
                  name='billingStatus'
                  className="border border-gray-300 rounded-md  w-full"
                  onChange={(e) => handleChangeFilter(e)}
                  filter
                />
              </div>
            }
            <div>
              <label htmlFor="emailId">Patient Name</label>
              <div className="relative">
                <InputText id="patientName" className="border border-gray-300 rounded-md p-2 pr-10 w-full" value={filterObj?.patientName ? filterObj?.patientName : ""} name="patientName" placeholder="Search for Patient Name" readOnly />
                <div className="absolute inset-y-0 right-0 flex items-center pr-2" onClick={toggleSidebarFilter}>
                  <svg
                    className="w-5 h-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M21 21l-5.2-5.2"></path>
                    <circle cx="10" cy="10" r="7"></circle>
                  </svg>
                </div>
              </div>
            </div>
            <div className="grid mt-36 ml-3 grid-cols-2">
              <div>
                <button className=" h-9  bg-[#004172] text-white rounded-md w-full"
                  onClick={filterService}
                >
                  Ok
                </button>
              </div>
              <div>
                <button className="ml-3 h-9 border border-[#004172] text-[#004172] font-bold font-monospace  rounded-md   w-full"
                  onClick={handleCancel}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </Sidebar>
        <div className="flex flex-col w-full mt-[7rem] overflow-auto " >
          <div className="flex justify-between mt-[3rem] ">
            <div>
              <h1 className="text-2xl  text-primary  font-semibold ">Billing Approval</h1>
            </div>
            <div className='flex  border h-10 rounded-md pt-1 pb-1 mr-12 justify-items-center'>
              <div className='flex  mx-2'>
                <Button className='flex gap-1 '
                  onClick={(e) => handleClickFilter({}, 'Filter')}
                >
                  <FaFilter className='text-primary' />
                  <h6 className='text-primary'>Filter</h6></Button>
              </div>
            </div>
          </div>
          {Object.keys(selected).length > 0 &&
            <div className="flex flex-end justify-end mt-4 mr-4">
              <div className="">
                <button className=" h-9 p-2 bg-[#004172] text-white rounded-md w-full"
                  onClick={() => onActionAll("A")}
                >
                  Approve Selected
                </button>
              </div>
              <div className="">
                <button className="ml-2 p-2 h-9 border border-[#004172] bg-[#fff] text-black rounded-md w-full"
                  onClick={() => onActionAll("R")}
                >
                  Reject Selected
                </button>
              </div>
            </div>
          }
          <div className="w-[99%] mt-8 rounded-[5px]" style={{ boxShadow: "0px 8px 25px 0px rgba(0, 0, 0, 0.06)" }}>
            <Tabs value={activeTab} onChange={(event, newValue) => handleChangeTab(newValue)}>
              <Tab label={<span style={{ fontWeight: 'bold' }}>Assign To Me</span>} />
              <Tab label={<span style={{ fontWeight: 'bold' }}>ALL</span>} />
            </Tabs>
            <TableContainer component={Paper} className="print-table" style={{ width: '100%', overflowX: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow >
                    {activeTab == 0 ?
                      <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>
                        <div style={{ width: 'max-content' }}>Select All <Checkbox
                          checked={Object.keys(selected).length > 0 && billinglist.every((notifi) => selected[notifi.id])}
                          onChange={handleSelectAll}
                        /></div>
                      </TableCell>
                      : null}
                    <TableCell style={{ fontSize: '15px', fontWeight: 600 }}>Enquiry ID</TableCell>

                    <TableCell style={{ fontSize: '15px', fontWeight: 600 }}>Patient ID</TableCell>
                    <TableCell style={{ fontSize: '15px', fontWeight: 600 }}>Patient Name</TableCell>
                    <TableCell style={{ fontSize: '15px', fontWeight: 600 }}>Procedure Name</TableCell>
                    <TableCell style={{ fontSize: '15px', fontWeight: 600 }}>eClinic Name</TableCell>

                    <TableCell style={{ fontSize: '15px', fontWeight: 600 }}>Preffered Hospital & Clinic</TableCell>
                    <TableCell style={{ fontSize: '15px', fontWeight: 600 }}>DoctorName</TableCell>

                    <TableCell style={{ fontSize: '15px', fontWeight: 600 }}>Gross Amount</TableCell>

                    <TableCell style={{ fontSize: '15px', fontWeight: 600 }}>MCI</TableCell>
                    <TableCell style={{ fontSize: '15px', fontWeight: 600 }}>Net Sale</TableCell>
                    <TableCell style={{ fontSize: '15px', fontWeight: 600 }}>Total Amount</TableCell>
                    <TableCell style={{ fontSize: '17px', fontWeight: 600, width: '200px' }} >Status</TableCell>
                    {activeTab == 0 ?
                      <TableCell style={{ fontSize: '17px', fontWeight: 600, width: '200px' }} >Action</TableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billinglist.map((notifi) => (
                    <TableRow key={notifi.id}>
                      {activeTab == 0 ?
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}><Checkbox
                          checked={selected[notifi.id] || false}
                          onChange={() => handleCheckboxChange(notifi.id)}
                        /></TableCell> : null}
                                            <TableCell style={{ fontSize: '13px', fontWeight: 500 }}>{notifi.serviceEnquiryId}</TableCell>

                      <TableCell style={{ fontSize: '13px', fontWeight: 500 }}>{notifi.patientId}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontWeight: 500 }}>{notifi.patientName}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontWeight: 500 }}>{notifi.procedureName}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontWeight: 500 }}>{notifi.cheCenterName}</TableCell>

                      <TableCell style={{ fontSize: '13px', fontWeight: 500 }}>{notifi.hospitalName}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontWeight: 500 }}>{notifi.referralUserName}</TableCell>

                      <TableCell style={{ fontSize: '13px', fontWeight: 500 }}>{notifi.gross}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontWeight: 500 }}>{notifi.mci}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontWeight: 500 }}>{notifi.yieldAmount}</TableCell>
                      <TableCell style={{ fontSize: '13px', fontWeight: 500 }}>{notifi.totalAmount}</TableCell>
                      <TableCell style={{ fontSize: '15px', fontWeight: 500 }}><span style={getbillingStyle(notifi.billingStatus)}>{notifi.billingStatus == 1 ? 'Billing Uploaded' : notifi.billingStatus == 3 ? 'Billing Completed' : notifi.billingStatus == 2 ? 'Billing Done' : notifi.billingStatus == 4 ? 'Billing Approved' : notifi.billingStatus == 5 ? 'Billing Rejected' : ''}</span></TableCell>
                      {activeTab == 0 ?
                        <TableCell style={{ fontSize: '12px', fontWeight: 500 }}>
                          <div className="">
                            <button className=" h-9 w-28 bg-[#004172] text-white rounded-md w-full"
                              onClick={() => onAction(notifi, "A")}
                            >
                              Approve
                            </button>
                            <button className="mt-1 w-28 h-9 border border-[#004172] bg-[#fff] text-black rounded-md w-full"
                              onClick={() => onAction(notifi, "R")}
                            >
                              Reject
                            </button>
                          </div>
                        </TableCell> : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
              <Pagination
                count={Math.ceil(billinglist[0]?.pagination?.totalRecords / 10)}
                page={page1}
                color="secondary"
                onChange={handlePageChange}
              />
            </Box>
          </div>

        </div>
      </OurLayouts >
      <PatientSearch
        showbar={patientSearch}
        setshowBar={toggleSidebarFilter}
        onSelect={handleSelectedData}
      />
    </>
  );
}

export default PracticeAdminBillList;