import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { APP_ROUTES } from "./approutes";
import LoginPage from "../components/Authentication/login";
import Dashboard from "../components/Dashboard";
import profileSreen from "../components/ProfileScreen/profileSreen";
import EnquryList from "../components/serviceEnquiry/enquiryList";
import ServicesList from "../components/serviceEnquiry/servicesList";
import filterEnquiry from "../components/serviceEnquiry/filterEnquiry";
import ApproveEnquiry from "../components/serviceEnquiry/approvalEnquiry";
import FinanceApproval from "../components/serviceEnquiry/financeApproval";
import PracticeAdmin from "../components/practiceAdmin/practiceAdminList";
import PracticeAdminBillList from "../components/practiceAdmin/practiceAdminBilling";

const OurRoutes = () => {
    return (
        <Router>
            <Switch>
                                <Route exact path={APP_ROUTES.LOGIN} component={LoginPage} />
                <Route exact path={APP_ROUTES.APPROVEENQ} component={ApproveEnquiry} />
                <Route exact path={APP_ROUTES.FINANCE} component={FinanceApproval} />


                <PrivateRoute>
                <Route exact path={APP_ROUTES.DASHBOARD} component={Dashboard} />
                <Route exact path={APP_ROUTES.ENQLIST} component={EnquryList} />
                <Route exact path={APP_ROUTES.PROFILESCREEN} component={profileSreen} />
                <Route exact path={APP_ROUTES.FILTERENQ} component={filterEnquiry} />
                <Route exact path={APP_ROUTES.PRALIST} component={PracticeAdmin} />

                <Route exact path={APP_ROUTES.SERVICE} component={ServicesList} />
                <Route exact path={APP_ROUTES.BILLAPPROVE} component={PracticeAdminBillList} />

                </PrivateRoute>



            </Switch>
        </Router>
    );
};
const PrivateRoute = ({ children }) => {
    const auth = checkIsLoggedIn();
    return auth ? children : navigateToLogin();
};
const navigateToLogin = () => {

    // secureStorage.removeItem('token');
    localStorage.clear();
    window.location.href = "/";
  };
const checkIsLoggedIn = () => {
    const loginObjStr = localStorage.getItem('loginObj');

    try {
        const loginObj = JSON.parse(loginObjStr);
        const userCode = loginObj?.userSession?.userCode || null;
        return userCode;
    } catch (error) {
        console.error('Error parsing JSON:', error);
        return null;
    }

}
export default OurRoutes