import http from "../httpcommon";

class notificationScreens {
  getNotification(params) {
   
   return http.get(`notification/list/filter?${params}`)
  }

  getNotificationSend(params) {
    return http.get(`notification/list/filter?${params}`)
   }
  sendnotification(payload){
    return http.post(`notification/`,payload);
  }
  emailSentAPI(emailId) {
    return http.get(`mails/listSentMailsFromUser?fromCode=${emailId}`);
  }
  getEmailMailbycode(testParam) {
    return http.get(`mails/listInboxMailsForUser?status=1&toCode=${testParam}`);
  }
  sendFeedBack(payload){
    return http.post(`Feedback/`,payload);
  }
  sendmail(payload){
    return http.post(`mails/`,payload);
  }
  searchData(payload){
    return http.get(`patient/list/filter?status=1&${payload}`);
  }
  searchFamilyData(payload){
    return http.get(`patient/list/filter?status=2&${payload}`);
  }
  

}

export default new notificationScreens();
