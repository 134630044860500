// In PatientSearch.js
import React, { useState, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import notificationScreens from "../../services/profileScreen/notificationScreens";
import { Paginator } from "primereact/paginator";
import "../../App.css";
import Loader from "../Loaders/Loader";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

const PatientSearch = (props) => {
  console.log(props, "Props");
  const { showbar, setshowBar } = props;
  const [mobileNumber, setmobileNumber] = useState();
  const [firstName, setfirstName] = useState();
  const [lastName, setlastName] = useState();
  const [patientData, setpatientData] = useState("");
  const [patientId, setpatientId] = useState("");
  const [email, setemail] = useState("");
  const [dob, setdob] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setloading] = useState(false)

  const toast = useRef(null);

  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, patientData.length);
  const currentData = patientData.slice(startIndex, endIndex);

  const formatDate = (date) => {
    if (!date) return "";
    let newDate = new Date(date + "");
    let month = "" + (newDate.getMonth() + 1),
      day = "" + newDate.getDate(),
      year = newDate.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  };

  const showInfo = (message) => {
    toast.current.show({ severity: 'info', summary: 'Info', detail: message, life: 3000 });
  }

  const showError = (message) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }
  const patientSearch = async () => {
    console.log("Patient Search Triggered");
    setloading(true);
    let queryParams = [];

    if (mobileNumber && mobileNumber.trim() !== "") {
      queryParams.push(`mobile=${encodeURIComponent(mobileNumber.trim())}`);
    }
    if (firstName && firstName.trim() !== "") {
      queryParams.push(`firstName=${encodeURIComponent(firstName.trim())}`);
    }
    if (lastName && lastName.trim() !== "") {
      queryParams.push(`lastName=${encodeURIComponent(lastName.trim())}`);
    }
    if (patientId && patientId.trim() !== "") {
      queryParams.push(`code=${encodeURIComponent(patientId.trim())}`);
    }
    if (email && email.trim() !== "") {
      queryParams.push(`email=${encodeURIComponent(email.trim())}`);
    }
    if (dob) {
      queryParams.push(`dob=${encodeURIComponent(formatDate(dob))}`);
    }

    let url = queryParams.length > 0 ? `${queryParams.join("&")}` : "";

    try {
      const res = await notificationScreens.searchData(url);

      console.log(res, "PATIENTDATA");
      setpatientData(res?.data);
      if (res?.data?.length > 0) {
        console.log(res?.data?.length, "DataLength");
        setTotalPages(Math.ceil(res?.data.length / pageSize));
        setloading(false);
      } else if (res?.data?.length === 0) {
        setloading(false);
        showInfo("No Data Found")
      }
    } catch (error) {
      console.error("Error searching patient:", error);
      setloading(false)
      showError("Something Went wrong")
    }
  };

  const patientFamilySearch = async () => {
    console.log("Patient Family Search Triggered");
if(!mobileNumber && !firstName && !lastName && !patientId && !dob && !firstName){
 return toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter atleast one field', life: 3000 });

}
setloading(true);

    let queryParams = [];

    if (mobileNumber && mobileNumber.trim() !== "") {
      queryParams.push(`mobile=${encodeURIComponent(mobileNumber.trim())}`);
    }
    if (firstName && firstName.trim() !== "") {
      queryParams.push(`firstName=${encodeURIComponent(firstName.trim())}`);
    }
    if (lastName && lastName.trim() !== "") {
      queryParams.push(`lastName=${encodeURIComponent(lastName.trim())}`);
    }
    if (patientId && patientId.trim() !== "") {
      queryParams.push(`code=${encodeURIComponent(patientId.trim())}`);
    }
    if (email && email.trim() !== "") {
      queryParams.push(`email=${encodeURIComponent(email.trim())}`);
    }
    if (dob) {
      queryParams.push(`dob=${encodeURIComponent(formatDate(dob))}`);
    }

    let url = queryParams.length > 0 ? `${queryParams.join("&")}` : "";

    try {
      const res = await notificationScreens.searchFamilyData(url);

      console.log(res, "PATIENTDATA");
      setpatientData(res?.data);
      if (res?.data?.length > 0) {
        console.log(res?.data?.length, "DataLength");
        setTotalPages(Math.ceil(res?.data.length / pageSize));
        setloading(false);
      }
    } catch (error) {
      console.error("Error searching patient:", error);
    }
  };



  const onPageChange = (event, tabName) => {
    const newPage = event.page + 1;
    setCurrentPage(newPage);
  };

  const handleSelectItem = (item) => {
    setSelectedItem(selectedItem === item ? null : item);
  };

  const handleOkayClick = () => {
    if (selectedItem) {
      props.onSelect({ code: selectedItem.code, name: selectedItem.name });
    }

    setSelectedItem(null);
    setmobileNumber("");
    setfirstName("");
    setlastName("");
    setpatientId("");
    setdob("");
    setemail("");
    setpatientData("");
    setshowBar(false);
  };

  return (
    <>
      <Sidebar
 style={{ width: '55%', borderBottom: '1px solid #ccc' }} dismissable={false}
        visible={showbar}
        position="right"
        onHide={() => {
          setSelectedItem(null);
          setmobileNumber("");
          setfirstName("");
          setlastName("");
          setpatientId("");
          setdob("");
          setemail("");
          setpatientData("");
          setshowBar(false);
        }}
      >
        {loading && <Loader />}
        <Toast ref={toast} />
        <div className="ml-6 justify-items-center">
        <h2 className="font-bold text-2xl leading-6 tracking-normal text-left text-[#262626]">
          Patient Search
        </h2>

        <div className="flex flex-row">
          {/* First Column */}
          <div className="flex flex-col">
            <label htmlFor="patientIdInput" className="block text-sm font-medium text-black mt-10 mb-4">
              Patient Id
            </label>
            <InputText
              value={patientId}
              placeholder="Enter Patient Id"
              onChange={(e) => setpatientId(e.target.value)}
              className="border rounded border-solid border-[#E2E2E2] box-border w-72 pl-3 pr-10"
            />
          </div>

          {/* Second Column */}
          <div className="flex flex-col ml-9">
            <label htmlFor="firstNameInput" className="block text-sm font-medium text-black mt-10 mb-4">
              First Name
            </label>
            <InputText
              value={firstName}
              placeholder="Enter First Name"
              onChange={(e) => setfirstName(e.target.value)}
              className="border rounded border-solid border-[#E2E2E2] box-border w-72 pl-3 pr-10"
            />
          </div>

          {/* Third Column */}
          <div className="flex flex-col ml-9">
            <label htmlFor="lastNameInput" className="block text-sm font-medium text-black mt-10 mb-4">
              Last Name
            </label>
            <InputText
              value={lastName}
              placeholder="Enter Last Name"
              onChange={(e) => setlastName(e.target.value)}
              className="border rounded border-solid border-[#E2E2E2] box-border w-72 pl-3 pr-10"
            />
          </div>
        </div>

        <div className="flex flex-row">
          {/* Fourth Column */}
          <div className="flex flex-col">
            <label htmlFor="mobileInput" className="block text-sm font-medium text-black mt-10 mb-4">
              Mobile Number
            </label>
            <InputText
              value={mobileNumber}
              placeholder="Enter Mobile Number"
              onChange={(e) => setmobileNumber(e.target.value)}
              className="border rounded border-solid border-[#E2E2E2] box-border w-72 pl-3 pr-10"
            />
          </div>

          {/* Fifth Column */}
          <div className="flex flex-col ml-9">
            <label htmlFor="emailInput" className="block text-sm font-medium text-black mt-10 mb-4">
              Email
            </label>
            <InputText
              value={email}
              placeholder="Enter Email"
              onChange={(e) => setemail(e.target.value)}
              className="border rounded border-solid border-[#E2E2E2] box-border w-72 pl-3 pr-10"
            />
          </div>

          {/* Sixth Column */}
          <div className="flex flex-col ml-9">
            <label htmlFor="dobInput" className="block text-sm font-medium text-black mt-10 mb-4">
              Date Of Birth
            </label>
            <Calendar
              value={dob}
              placeholder="Enter Date Of Birth"
              onChange={(e) => setdob(e.value)}
              showIcon
              className="border rounded border-solid border-[#E2E2E2] box-border w-72 pl-3 pr-10"
            />
          </div>
        </div>

        <div className="flex flex-row justify-center mt-12">
          {/* Button for searching family members */}
          <Button
            label="Search Family Member"
            className="w-72 h-10 border border-[#004172] text-[#004172] font-bold font-monospace rounded-md ml-4"
            onClick={patientFamilySearch}
          />

          {/* Button for searching patients */}
          <Button
            label="Search"
            className="w-72 h-10 border border-[#004172] bg-[#004172] text-[#FFFFFF] rounded-md font-monospace ml-3 font-bold"
            onClick={patientSearch}
          />
        </div>

        {patientData.length > 0 && (
          <h2 className="font-bold text-2xl leading-6 tracking-normal text-left text-[#262626] mt-12">
            Check Patient Details
          </h2>
        )}
        {currentData.length > 0 &&
          currentData.map((data, index) => (
            <>
              <>
                <div
                  className={`w-[90%] h-40 bg-[#FFFFFF] border border-[#E2E2E5] ml-14 mt-8 rounded-md cursor-pointer ${selectedItem === data ? "selected-item" : ""
                    }`}
                  key={index}
                  onClick={() => handleSelectItem(data)}
                >
                  <div className="w-full h-1/3 bg-[#00B2DD0D] flex flex-row">
                    <h2 className="font-bold text-lg leading-6  text-[#262626] p-4 overflow-hidden">
                      {data?.name}
                    </h2>
                    <h2 className="font-bold ml-44 text-sm leading-6  text-[#262626] p-4">
                      {data?.code}
                    </h2>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex flex-row mt-4 ml-4">
                      <h1
                        className="font-medium"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          lineHeight: "22px",
                        }}
                      >
                        Gender:
                      </h1>
                      <h1
                        className="font-normal ml-1"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          lineHeight: "22px",
                        }}
                      >
                        {data?.gender}
                      </h1>
                    </div>
                    <div className="flex flex-row mt-4 ">
                      <h1
                        className="font-medium "
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          lineHeight: "22px",
                          marginLeft: "72px",
                        }}
                      >
                        DOB:
                      </h1>
                      <h1
                        className="font-normal ml-2"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          lineHeight: "22px",
                        }}
                      >
                        {data?.dob}
                      </h1>
                    </div>
                    <div className="flex flex-row mt-4 ">
                      <h1
                        className="font-medium "
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          lineHeight: "22px",
                          marginLeft: "72px",
                        }}
                      >
                        Status:
                      </h1>
                      <h1
                        className="font-normal ml-2"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          lineHeight: "22px",
                        }}
                      >
                        {data?.status}
                      </h1>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex flex-row mt-4 ">
                      <h1
                        className="font-medium ml-4 "
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          lineHeight: "22px",
                        }}
                      >
                        Mobile:
                      </h1>
                      <h1
                        className="font-normal ml-2"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          lineHeight: "22px",
                        }}
                      >
                        {data?.mobile}
                      </h1>
                    </div>
                    <div className="flex flex-row mt-4 ">
                      <h1
                        className="font-medium "
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          lineHeight: "22px",
                          marginLeft: "35px",
                        }}
                      >
                        Mail ID:
                      </h1>
                      <h1
                        className="font-normal ml-2"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          lineHeight: "22px",
                        }}
                      >
                        {data?.email}
                      </h1>
                    </div>
                  </div>
                </div>
              </>
            </>
          ))}
        <Paginator
          first={(currentPage - 1) * pageSize}
          rows={pageSize}
          totalRecords={patientData.length}
          onPageChange={onPageChange}
          className="ml-32"
        />
        {patientData.length > 0 && (
          <button
            className="w-64 h-10 border border-[#004172] bg-[#004172] text-[#FFFFFF] first-letter:font-bold font-monospace  rounded-md mt-16 ml-36"
            onClick={handleOkayClick}
          >
            Search
          </button>
        )}
        </div>
      </Sidebar>
    </>
  );
};

export default PatientSearch;
