import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import curebay from '../../assets/images/CBLogo.png'

const ApproveEnquiry = (props) => {
    const location = useLocation();
    const [approveUrl, setapproveUrl] = useState('');
    const [rejectUrl, setrejectUrl] = useState('');

    const queryParams = new URLSearchParams(location.search);
    
    const enqId = queryParams.get('Id');
    const patientName = queryParams.get('patientName');
    const hospitalName = queryParams.get('hospitalName');
    const typeofSurgery = queryParams.get('typeofSurgery');
    const patientLocation = queryParams.get('patientLocation');    
    const patientId = queryParams.get('patientId');
    const surgeryConfirmDate = queryParams.get('surgeryConfirmDate');



  useEffect(() => {
    let  encryptData1 = btoa(enqId+"|A|F")
    let  encryptData2= btoa(enqId+"|R|F")

    console.log(encryptData1)
   setapproveUrl(`${process.env.REACT_APP_DIAGNOSTIC_BASEURL +'patientReferralConfirmation?parameter1='+encryptData1}`)
  setrejectUrl(`${process.env.REACT_APP_DIAGNOSTIC_BASEURL +'patientReferralConfirmation?parameter1='+encryptData2}`)
  }, []);
return (
    <>
   <div className="flex justify-center items-center w-full h-screen">
  <div className="container">
    <div className="flex justify-center items-center h-full">
      <div className="w-full max-w-md mx-auto">
        <div className="mb-4 flex justify-start">
          <img src={curebay}   alt="Curebay" />
        </div>
        <div className="w-full">
          <p>Hi,</p>
          <p>We are referring the below patient to <strong>{hospitalName}</strong> for the mentioned service. Please acknowledge.</p>
          <ul className='mt-4'>
          <strong>Patient ID:</strong> {patientId}<br />
            <strong>Patient Name:</strong> {patientName}<br />
            <strong>Patient Location:</strong> {patientLocation}<br />
            <strong>Surgery Type:</strong> {typeofSurgery}<br />
            <strong>Surgery Confirmed Date:</strong> {surgeryConfirmDate}

          </ul>
          <p className='mt-4'>Do you accept to refer a patient to your hospital?</p>
        </div>
        <div className="flex items-center gap-4 mt-4">
          <a href={approveUrl} className="btn bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Accept</a>
          <a href={rejectUrl} className="btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Reject</a>
        </div>
        <p className="mt-4">Thanks,<br />CureBay Support</p>
      </div>
    </div>
  </div>
</div>

    </>
)

}
export default ApproveEnquiry;