import React from "react";
import mail from "../../assets/images/mail.svg";
import notifications from "../../assets/images/notifications.svg";
import { InputText } from "primereact/inputtext";
import searchicon from "../../assets/images/searchicon.svg";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { APP_ROUTES } from "../../routes/approutes";
import { useHistory } from "react-router-dom";
import Notifications from "./notifications";
import Email from "../ProfileScreen/Email";
import { Sidebar } from "primereact/sidebar";
import { InputTextarea } from "primereact/inputtextarea";
import { TabView, TabPanel } from "primereact/tabview";
import notificationScreens from "../../services/profileScreen/notificationScreens";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Loader from "../Loaders/Loader";
import { Toast } from "primereact/toast";

function Header() {
  const settings = [
    { name: "Profile", path: APP_ROUTES.PROFILESCREEN },
    { name: "Feedback" },
    { name: "Logout", path: "/" },
  ];

  const userObj = JSON.parse(localStorage.getItem("loginObj"));
  const history = useHistory();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showEmails, setshowEmails] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [subject, setsubject] = useState();
  const [body, setbody] = useState();
  const [liveTime, setLiveTime] = useState("");
  const [loading, setloading] = useState(false);
  const toast = useRef(null);

  console.log(userObj);
  const testParam = userObj.user?.code;

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuItemClick = (path,name) => {
    handleCloseUserMenu();
    if(name == 'Logout'){
      localStorage.clear();

    }
    history.push(path);
    if (path) {
    } else {
      setShowSidebar(true);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };
  const toggleEmails = () => {
    setshowEmails(!showEmails);
  };

  const getCurrentTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const date = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    const formattedTime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
    setLiveTime(formattedTime);
  };
  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };
  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  useEffect(() => {
    const timerID = setInterval(() => getCurrentTime(), 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  const sendFeedback = () => {
    console.log("Inside Feedback function");
    setloading(false);
    const payload = {
      subject: subject,
      content: body,
      modifiedDate: liveTime,
      modifiedBy: testParam,
      createdDate: liveTime,
      createdBy: testParam,
      fromCode: testParam,
      status: 1,
    };
    console.log("Feedback Payload", payload);
    notificationScreens
      .sendFeedBack(payload)
      .then((res) => {
        console.log(res?.data, "POST API RESULT");
        if (res?.data === 1) {
          setsubject(null);
          setbody(null);
          showSuccess("Feeback Sent Successfull");
          setShowSidebar(false);
        }
      })
      .catch((error) => {
        let errorMessage = "Error sending mail";
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          errorMessage = errorData.message;
          if (
            errorData.details &&
            errorData.details.length > 1 &&
            errorData.details[1]
          ) {
            errorMessage += `: ${errorData.details[1]}`;
          }
        }

        showError(errorMessage);
      });
  };

  return (
    < >
      <Toast ref={toast} />
      
      <Sidebar
        visible={showSidebar}
        position="right"
        onHide={() => {
          setShowSidebar(false);

          setsubject(null);
          setbody(null);
        }}
        className="w-[500px]"
      >
        <h2 className="font-bold text-2xl leading-6 tracking-normal text-left text-[#262626]">
          Feedback
        </h2>

        <div className="flex flex-col">
          <label
            htmlFor="nameInput"
            className="block text-sm font-medium text-black font-medium mb-4 mt-8"
          >
            Subject
          </label>
          <InputTextarea
            value={subject}
            placeholder="Type Here ......"
            rows={2}
            cols={2}
            onChange={(e) => setsubject(e.target.value)}
            className="border rounded border-solid border-[#E2E2E2] box-border w-full pl-3 pr-10"
          />
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="nameInput"
            className="block text-sm font-medium text-black font-medium mb-4 mt-8"
          >
            Message
          </label>
          <InputTextarea
            value={body}
            placeholder="Type Here ......"
            rows={10}
            cols={30}
            onChange={(e) => setbody(e.target.value)}
            className="border rounded border-solid border-[#E2E2E2] box-border w-full pl-3 pr-10"
          />
        </div>
        <button
          className="w-64 h-10 border border-[#004172] bg-[#004172] text-[#FFFFFF] first-letter:font-bold font-monospace  rounded-md mt-6 ml-20"
          onClick={sendFeedback}
        >
          Send
        </button>
      </Sidebar>
      <div className="bg-white w-[87%] rounded-tl-3xl rounded-tr-none rounded-br-none rounded-bl-none shadow-[0px_8px_18px_0px_rgba(0,0,0,0.078)] p-4 fixed ">
        <div className="flex flex-row">
          {/* <div className="w-1/3 bg-white border border-gray-300 rounded-tl-lg rounded-br-lg ml-4 mt-4 flex items-center rounded-bl-lg">
            <InputText
              placeholder="Search ...."
              className="w-full h-full border-none p-2"
            />
            <img className="cursor-pointer" src={searchicon} alt="" />
          </div> */}
          <div className="flex items-center ml-auto mr-4 mt-4">
            <img
              className="mr-4 cursor-pointer"
              src={mail}
              alt=""
              onClick={toggleEmails}
            />
            <img
              className="mr-4 cursor-pointer"
              src={notifications}
              alt=""
              onClick={toggleNotifications}
            />
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting.name}
                    onClick={() => handleMenuItemClick(setting.path,setting.name)}
                  >
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <div className="flex flex-col">
              <h1 className="ml-2 font-medium  text-primary text-bold ">
                {userObj?.user?.firstName} {userObj?.user?.lastName}
              </h1>
              <h1 className="ml-2 font-medium font-mono">
                {userObj?.user?.mobile}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Notifications show={showNotifications} setShow={toggleNotifications} />
      <Email show={showEmails} setShow={toggleEmails} />
    </>
  );
}

export default Header;
