import React, { useEffect } from "react";
import { useState , useRef } from "react";
import NavBar from "../NavigationBar/NavBar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "../../App.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Divider from "@mui/material/Divider";
import ProfileImage from "../../assets/images/ProfileImage.svg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Sidebar } from "primereact/sidebar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import profileServices from "../../services/profileScreen/profileServices";
import Header from "../../components/Shared/header";
import OurLayouts from "../Layouts";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { Toast } from "primereact/toast";
import moment from 'moment'
import apiService from "../../services/apiservice";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Paper,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Modal,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Box,
  Pagination,
  Autocomplete,
  TableSortLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function ProfileScreen() {
  const salutation = [
    { name: "Dr", code: "Dr" },
    { name: "Mr", code: "Mr" },
    { name: "Mrs", code: "Mrs" },
  ];

  const gender = [
    { name: "Male", code: "M" },
    { name: "Female", code: "F" },
  ];
  const history = useHistory();


  const [visible, setVisible] = useState(false);
  const [userData, setuserData] = useState([""]);
  const [updateData, setupdateData] = useState([""]);
  const [consentData, setconsentData] = useState([""]);
  const [privacyPolicy, setprivacyPolicy] = useState(false);
  const [termsConditions, settermsConditions] = useState(false);
  const [loading, setloading] = useState(false);
  const [termsAccept, setTermsAccept] = useState(true);
  const [termsView, setTermsView] = useState(false);
  const [policyAccept, setPolicyAccept] = useState(true);
  const [privacyView, setPrivacyView] = useState(false);
  const [consentVersionT, setConsentVersionT] = useState();
  const [consentVersionP, setConsentVersionP] = useState();
  // const [versionTextChange, setVersionText] = useState(false);

  const toast = useRef(null);



  const userObj = JSON.parse(localStorage.getItem("loginObj"));
  console.log(userObj);
  const testParam = userObj.user?.code;
  console.log(testParam);
  useEffect(() => {
    try {
      setloading(true);
      profileServices.getDataAPI(testParam).then((res) => {
        setuserData(res?.data);
        console.log(res?.data, "GETAPI");
        setloading(false);
      });
    } catch (error) {
      console.error("Error parsing loginObj from localStorage", error);
      setloading(false);
    }
  }, []);

  useEffect(() => {
    console.log(userData, "DATA");
  }, [userData]);

  useEffect(() => {
    const payload = {
      userId: testParam,
      status: 1,
    };
    try {
      profileServices.getUserConsentData(payload).then((res) => {
        console.log(res?.data?.data, "Consent Data");
        setconsentData(res?.data?.data);
      });
    } catch (error) {
      console.error("Error parsing loginObj from localStorage", error);
    }
  }, []);

  useEffect(() => {
    const privacyPolicyVersion = consentData?.[0]?.privacyPolicyConsent;
    const termsConditionsVersion = consentData?.[0]?.termsAndConditionConsent;
    if (privacyPolicyVersion === 1 && termsConditionsVersion === 1) {
      setprivacyPolicy(true);
      settermsConditions(true);
    } else {
      setprivacyPolicy(false);
      settermsConditions(false);
    }
  }, []);

  useEffect(() => {
    console.log(privacyPolicy, termsConditions, "Check");
  }, [privacyPolicy, termsConditions]);

  const handleUserDataChange = (name, value) => {
    setuserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };
  useEffect(() => {
    apiService.getUserConsent().then((result) => {
      const policy = result.data.filter(val => val.constentName === "PrivacyPolicy")
      setConsentVersionP(policy[0])
      const terms = result.data.filter(val => val.constentName === "TermsAndCondition")
      setConsentVersionT(terms[0])
  })
    setupdateData(userData);
  }, [userData]);

  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const onUpdate = () => {
    console.log("Update Button");
    setloading(true);
    const payload = {
      id: updateData.id,
      photo: "",
      photoName: updateData.photoName,
      code: updateData.code,
      registrationNo: updateData.registrationNo,
      speciality: updateData.speciality,
      maritalStatus: updateData.maritalStatus,
      address1: updateData.address1,
      address2: updateData.address2,
      city: updateData.city,
      state: updateData.state,
      country: updateData.country,
      pinCode: updateData.pinCode,
      aboutMe: updateData.aboutMe,
      isHospitalManagement: updateData.isHospitalManagement,
      salutation: updateData.salutation,
      firstName: updateData.firstName,
      lastName: updateData.lastName,
      dob: updateData.dob,
      email: updateData.email,
      gender: updateData.gender,
      mobile: updateData.mobile,
      qualification: updateData.qualification,
      isApproved: updateData?.isApproved,
      status: userObj.user?.userRolesList?.[0]?.status,
      roleCode: userObj.user?.userRolesList?.[0]?.roleCode,
      mciverification: updateData.mciverification,
      modifiedDate: userObj?.user?.userRolesList?.[0]?.modifiedDate,
      modifiedBy: userObj?.user?.userRolesList?.[0]?.modifiedBy,
    };

    console.log(payload);
    profileServices.updateUserdata(payload).then((res) => {
      console.log(res, "Updated");
      if(res?.data === 1){
        setloading(false);
        showSuccess("Updated Successfully")
      }
    });
  };

  const formatDate = (date) => {
    if (!date) return "";

    const d = new Date(date);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  };
const cancelProfile =()=>{
  window.location.href = "/dashboard";

}
  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [month, day, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  };

  const handlePrivacyPolicyChange = (event) => {
    setprivacyPolicy(event.target.checked);
  };
  const handleTermsConditionsChange = (event) => {
    settermsConditions(event.target.checked);
  };
  const consentUpdate = async () => {
    const payload = {
      createdBy: "JICMXDKQ300922",
      createdDate: "2024-03-13 06:40:01",
      modifiedBy: "JICMXDKQ300922",
      modifiedDate: "2024-03-13 06:40:01",
      privacyPolicyConsent: 0,
      sourceChannel: "ADMIN",
      status: 1,
      termsAndConditionConsent: 0,
      type: "S",
      userId: "JICMXDKQ300922",
      privacyVersionNumber: "V4",
      tcVersionNumber: "V4",
    };
    console.log(payload);
  };
  const toggleSidebar = () => {
    setVisible(!visible);
  };
  const onConsentAgree = () => {
    const payload = {
        "createdBy": userObj.user?.code,
        "createdDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        "modifiedBy": userObj.user?.code,
        "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        "privacyPolicyConsent": policyAccept ? 1 : 0,
        "sourceChannel": "LB",
        "status": 1,
        "termsAndConditionConsent": termsAccept ? 1 : 0,
        "type": "S",
        "userId": userObj.user?.code,
        "privacyVersionNumber": consentVersionP.versionNumber,
        "tcVersionNumber": consentVersionT.versionNumber
    }
    apiService.userConsentPost(payload).then((res) => {
        if (res.data === 1) {
          history.push('/')
        }
    })
}
  return (
    <>
      <OurLayouts>
      <Backdrop
     sx={{ color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress size={80} color="inherit"   />
      </Backdrop>
      <Toast ref={toast} />
        <div
          className="flex flex-col h-screen w-screen"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: "2rem",
          }}
        >
          <div
            className="flex flex-row"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "2rem",
            }}
          >
            <div >
          <Button icon="pi pi-bars" onClick={toggleSidebar} />
          <Sidebar style={{ width: '588px' }} visible={visible} onHide={() => setVisible(false)} position="right"
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '1rem', borderBottom: '1px solid #ccc' }}>
              <div className="font-poppins text-base font-semibold leading-6 tracking-normal text-left"> Update Consent</div>
              <Button icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text p-button-danger p-button-sm" />
            </div>

            {/* <div  className="mt-10" >
<h1  className="text-2xl font-semibold mb-8">Terms of use and Privacy Policy</h1>
            </div> */}

            <div className="bg-white p-5">
                                <p className='className="text-2xl font-semibold mb-8'>
                                    {
                                        <b>Please Accept Terms of use and Privacy Policy to continue</b>
                                    }
                                </p>
                                <div className='mr-20'>
                                <div className="flex cursor-pointer mt-4">
                                    <div className=" flex space-x-2">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => setTermsAccept(e.target.checked)}
                                            class="form-radio"
                                            name="accountType"
                                            value="personal"
                                            checked={termsAccept}
                                        />
                                        <div className="text-xs font-medium  font-montserrat">
                                            <p className="text-brand-primary font-medium text-sm cursor-pointer" onClick={() => setTermsView(true)} ><u>Terms and conditions</u></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex cursor-pointer mt-4">

                                    <div className=" flex space-x-2">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => setPolicyAccept(e.target.checked)}
                                            class="form-radio"
                                            name="accountType"
                                            value="personal"
                                            checked={policyAccept}
                                        />
                                        <div className="text-xs font-medium  font-montserrat">
                                            <p className="text-brand-primary font-medium text-sm cursor-pointer" onClick={(e) => setPrivacyView(true)} ><u>Privacy policy</u></p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </div>

            <div className='mt-32'>
              <button className="w-32 h-9  bg-[#004172] text-white rounded-md w-full"
                onClick={onConsentAgree}>
                Update
              </button>

            </div>

          </Sidebar>
        </div>
            <div className="flex flex-col">
              <div
                className="mt-20 ml-8"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "2rem",
                }}
              >
                <h1 className="font-bold text-2xl leading-6 tracking-normal text-left text-[#262626]">
                  My Profile
                </h1>
                <div
                  className="w-[1138px] h-[118px] mt-8 ml-8 rounded-[5px] bg-[#FFFFFF] flex flex-row"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <div className="flex flex-row">
                    <img
                      src={ProfileImage}
                      alt=""
                      className="p-3 cursor-pointer"
                    />

                    <div className="flex flex-col">
                      <h1 className="font-bold text-2xl leading-6 tracking-normal text-left text-[#262626] pt-4 pl-6 pb-2">
                        {userData?.firstName} {userData?.lastName}
                      </h1>

                      <h1 className="font-normal text-lg  tracking-normal text-left text-[#262626] pl-8 ">
                        {userData?.mobile}
                      </h1>
                    </div>

                    <button
                      className="w-40 h-10 border border-[#004172] text-[#004172] font-bold font-monospace  rounded-md ml-[622px] mt-7"
                      onClick={() => setVisible(true)}
                    >
                      Update Consent
                    </button>
                  </div>
                </div>
                <div
                  className="w-[1138px] h-[600px] mt-8 ml-8 rounded-[5px]"
                  style={{ boxShadow: "0px 8px 25px 0px rgba(0, 0, 0, 0.06)" }}
                >
                  <h1 className="font-bold text-2xl leading-6 tracking-normal text-left text-[#262626] pt-10 pl-6 pb-4">
                    Personal Information
                  </h1>
                  <Divider
                    style={{ marginRight: "20px", marginLeft: "20px" }}
                  />
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          User Id
                        </label>
                        <InputText
                          id="nameInput"
                          disabled
                          value={userData?.code}
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext "
                        />
                      </div>
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Salutation
                        </label>
                        <Dropdown
                          value={userData?.salutation}
                          options={salutation}
                          optionLabel="name" // This tells Dropdown how to display the options
                          placeholder="Select a Salutation"
                          editable
                          onChange={(e) =>
                            handleUserDataChange("salutation", e.value.code)
                          }
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext"
                        />
                      </div>
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700 "
                        >
                          First Name
                        </label>
                        <InputText
                          id="nameInput"
                          value={userData?.firstName}
                          onChange={(e) =>
                            handleUserDataChange("firstName", e.target.value)
                          }
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext "
                        />
                      </div>
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Last Name
                        </label>
                        <InputText
                          id="nameInput"
                          value={userData?.lastName}
                          onChange={(e) =>
                            handleUserDataChange("lastName", e.target.value)
                          }
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Profession
                        </label>
                        <InputText
                          disabled
                          id="nameInput"
                          value={userObj?.user?.userRolesList?.[0]?.roleName}
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext"
                        />
                      </div>
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Date of Birth
                        </label>
                        <Calendar
                          value={parseDate(userData?.dob)}
                          focused
                          label="Date of Birtg"
                          onChange={(e) =>
                            handleUserDataChange("dob", formatDate(e.value))
                          }
                          showIcon
                          className="w-60 h-10 mt-1  border rounded border-solid border-[#E2E2E2] box-border p-inputtext"
                        />
                      </div>
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email Id
                        </label>
                        <InputText
                          id="nameInput"
                          value={userData?.email}
                          onChange={(e) =>
                            handleUserDataChange("email", e.target.value)
                          }
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext"
                        />
                      </div>
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Gender
                        </label>
                        <Dropdown
                          value={gender.find(
                            (g) => g.code === userData?.gender
                          )}
                          options={gender}
                          optionLabel="name"
                          placeholder="Select a Gender"
                          editable
                          onChange={(e) =>
                            handleUserDataChange("gender", e.value.code)
                          }
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Mobile Number
                        </label>
                        <InputText
                          id="nameInput"
                          value={userData?.mobile}
                          onChange={(e) =>
                            handleUserDataChange("mobile", e.target.value)
                          }
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext"
                        />
                      </div>
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Qualifications
                        </label>
                        <InputText
                          id="nameInput"
                          value={userData?.qualification}
                          onChange={(e) =>
                            handleUserDataChange(
                              "qualification",
                              e.target.value
                            )
                          }
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext"
                        />
                      </div>
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Address 1
                        </label>
                        <InputText
                          id="nameInput"
                          value={userData?.address1}
                          onChange={(e) =>
                            handleUserDataChange("address1", e.target.value)
                          }
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext"
                        />
                      </div>
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Address 2
                        </label>
                        <InputText
                          id="nameInput"
                          value={userData?.address2}
                          onChange={(e) =>
                            handleUserDataChange("address2", e.target.value)
                          }
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          City
                        </label>
                        <InputText
                          id="nameInput"
                          value={userData?.city}
                          disabled
                          onChange={(e) =>
                            handleUserDataChange("city", e.target.value)
                          }
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext"
                        />
                      </div>
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          State
                        </label>
                        <Dropdown
                          value={userData?.state}
                          // onChange={(e) => setSelectedCity(e.value)}
                          options={gender}
                          optionLabel="name"
                          editable
                          disabled
                          placeholder="Select a City"
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border"
                        />
                      </div>
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Country
                        </label>
                        <InputText
                          id="nameInput"
                          disabled
                          value={userData?.country}
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext"
                        />
                      </div>
                      <div className="pt-10 pl-6">
                        <label
                          htmlFor="nameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Pincode
                        </label>
                        <InputText
                          id="nameInput"
                          disabled
                          value={userData?.pinCode}
                          className="mt-1 w-60 h-10 border rounded border-solid border-[#E2E2E2] box-border p-inputtext"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-12 flex flex-row ">
                    <button className="w-40 h-10 border border-[#004172] text-[#004172] font-bold font-monospace  rounded-md ml-4"  onClick={cancelProfile}
>
                      Cancel
                    </button>

                    <button
                      className="w-40 h-10 border border-[#004172] bg-[#004172] text-[#FFFFFF] rounded-md font-monospace  ml-3 font-bold"
                      onClick={onUpdate}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal open={termsView}
                onClose={() => setTermsView(false)} >
                <>
                    <Box
                        sx={{ width: 1000, height: 600 }}>
                        <div className='bg-white'>
                            <CardHeader
                                title={
                                    <Typography variant="h6" style={{
                                        fontSize: '18px',
                                        fontWeight: 500,
                                    }}>Terms of use</Typography>
                                }
                                action={
                                    <IconButton onClick={() => setTermsView(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    borderBottom: '1px solid #e0e0e0',
                                }}
                            />
                            <Divider />
                            {consentVersionP &&
                                <div className="bg-white">
                                    <iframe src={`${process.env.REACT_APP_IMAGE_URL + consentVersionT.consentFile}`}
                                        height="450px"
                                        width="900px" />
                                </div>
                            }
                        </div>
                    </Box>
                </>
            </Modal>
            <Modal open={privacyView}
                onClose={() => setPrivacyView(false)} >
                <>
                    <Box
                        sx={{width: 1000, height: 600 }}>
                        <div className='bg-white'>
                            <CardHeader
                                title={
                                    <Typography variant="h6" style={{
                                        fontSize: '18px',
                                        fontWeight: 500,
                                    }}>Privacy Policy</Typography>
                                }
                                action={
                                    <IconButton onClick={() => setPrivacyView(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    borderBottom: '1px solid #e0e0e0',
                                }}
                            />
                            <Divider />
                            {consentVersionP &&
                                <div className="bg-white">
                                    <iframe src={`${process.env.REACT_APP_IMAGE_URL + consentVersionP.consentFile}`}
                                        height="450px"
                                        width="900px" />
                                </div>
                            }
                        </div>
                    </Box>
                </>
            </Modal>
      </OurLayouts>
    </>
  );
}
