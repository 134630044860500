const APP_ROUTES = {
    LOGIN: "/",
    DASHBOARD: "/dashboard",
    PROFILESCREEN:'/profile',
    ENQLIST: "/enqList",
    APPROVEENQ: "/patientReferralConfirmationUrl",
    SERVICE:"/serviceList",
    FILTERENQ:"/filterEnquiry",
    DASHENQ:"/dashEnquiry",
    FINANCE:'/billingConformationApproval',
    PRALIST:'/practiceAdminList',
    BILLAPPROVE : '/billingApproval'





}  
export {APP_ROUTES} 