import React from "react";
import { Sidebar } from "primereact/sidebar";
import { useState, useEffect, useRef } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import blueline from "../../assets/images/blueline.svg";
import search from "../../assets/images/search.svg";
import { toast } from "react-toastify";
import { InputTextarea } from "primereact/inputtextarea";
import Divider from "@mui/material/Divider";
import notificationScreens from "../../services/profileScreen/notificationScreens";
import { Paginator } from "primereact/paginator";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import PatientSearch from "../../components/Shared/patientSearch";
import Loader from "../Loaders/Loader";
import { Toast } from "primereact/toast";

export default function Notifications(props) {
  const { show, setShow } = props;
  const [notificationData, setnotificationData] = useState([""]);
  const [sendNotification, setSendNotification] = useState([]);
  const [lastSearchTrigger, setLastSearchTrigger] = useState("");
  const [loading, setloading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [tabSearchItems, setTabSearchItems] = useState({
    received: null,
    sent: null,
    newNotification: null,
  });

  const [liveTime, setLiveTime] = useState("");
  const [patientSearch, setpatientSearch] = useState(false);
  const toast = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [value, setValue] = useState("");
  const [fromdate, setfromdate] = useState(null);
  const [todate, settodate] = useState(null);

  const [subject, setsubject] = useState();
  const [body, setbody] = useState();
  const [retrivedData, setretrivedData] = useState();
  const pageSize = 5;
  const [searchItem, setsearchItem] = useState("");
  const [currentPagesent, setCurrentPagesent] = useState(1);
  const [totalPagessent, setTotalPagessent] = useState(0);

  const startIndexsent = (currentPagesent - 1) * pageSize;
  const endIndexsent = Math.min(
    startIndexsent + pageSize,
    notificationData.length
  );
  const sentMailData = notificationData.slice(startIndexsent, endIndexsent);

  const toggleSidebar = () => {
    setShow(!show);
  };

  const onPageChange = (event, tabName) => {
    const newPage = event.page + 1;
    setCurrentPage(newPage);
  };

  useEffect(() => {
    console.log("tabSearchItems updated:", tabSearchItems);
  }, [tabSearchItems]);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, sendNotification.length);
  const currentData = sendNotification.slice(startIndex, endIndex);

  // console.log(currentData, "CURRENT");
  const userObj = JSON.parse(localStorage.getItem("loginObj"));
  // console.log(userObj);
  const testParam = userObj.user?.code;
  // console.log(testParam);
  const toName =
    userObj?.user?.salutation +
    " " +
    userObj?.user?.firstName +
    " " +
    userObj?.user?.lastName;

  const togglePatientSearchbar = () => {
    setpatientSearch(!patientSearch);
  };

  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };
  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };
  const showInfo = (message) => {
    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: message,
      life: 3000,
    });
  };
  const handleSelectedData = (selectedData) => {
    const tabKeys = ["received", "sent", "newNotification"];
    const activeTabKey = tabKeys[activeIndex];

    console.log(`Updating ${activeTabKey} with:`, selectedData);

    setTabSearchItems((prev) => ({
      ...prev,
      [activeTabKey]: selectedData,
    }));
  };

  console.log(searchItem, "SearchItem");
  useEffect(() => {
    notificationScreens
      .getNotification("trigeredTo=" + testParam)
      .then((res) => {
        // console.log(res?.data, "Notify");
        setnotificationData(res?.data);
        setTotalPagessent(Math.ceil(res?.data.length / pageSize));
      })
      .catch((error) => {
        // console.error("Error fetching notifications:", error);
        toast("Something Went Wrong");
      });
  }, []);

  useEffect(() => {
    notificationScreens
      .getNotificationSend("trigeredBy=" + testParam)
      .then((res) => {
        // console.log(res?.data, "NotifySend");
        setSendNotification(res?.data);
        setTotalPages(Math.ceil(res?.data.length / pageSize));
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
        toast("Something Went Wrong");
      });
  }, []);

  const formatDate = (date) => {
    if (!date) return "";
    let newDate = new Date(date + "");
    let month = "" + (newDate.getMonth() + 1),
      day = "" + newDate.getDate(),
      year = newDate.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  };

  console.log(props);
  console.log(totalPages);
  console.log(fromdate, todate, "Dates");
  console.log(
    formatDate("fromDate" + fromdate) + "&toDate=" + formatDate(todate)
  );

  const onSearch = (activeIndexName) => {
    let url = "";
    setloading(true);
    const searchKeyMap = {
      sent: "trigeredTo",
      received: "trigeredBy",
    };

    const activeSearchItem = tabSearchItems[activeIndexName];

    if (activeIndexName === "received") {
      url = `trigeredTo=${testParam}`;
    } else if (activeIndexName === "sent") {
      url = `trigeredBy=${testParam}`;
    }

    if (fromdate && todate) {
      url += `&fromDate=${formatDate(fromdate)}&toDate=${formatDate(todate)}`;
    }

    if (activeSearchItem?.code) {
      url += `&${searchKeyMap[activeIndexName]}=${activeSearchItem.code}`;
    }

    const apiCall =
      activeIndexName === "received"
        ? notificationScreens.getNotification
        : notificationScreens.getNotificationSend;

    apiCall(url)
      .then((res) => {
        if (activeIndexName === "received") {
          setnotificationData(res?.data);
          setloading(false);
          setTotalPagessent(Math.ceil(res?.data.length / pageSize));
          if (res?.data?.length === 0) {
            showInfo("No Data Found");
          }
        } else if (activeIndexName === "sent") {
          setSendNotification(res?.data);
          setTotalPages(Math.ceil(res?.data.length / pageSize));
          setloading(false);
          if (res?.data?.length === 0) {
            showInfo("No Data Found");
          }
        }
      })
      .catch((error) => {
        toast("Something Went Wrong");
        setloading(false);
        showError("Server Error");
      });
  };

  console.log("Tab Index" + activeIndex);

  const onTabChange = (e, tabName) => {
    setActiveIndex(e.index);
    setfromdate(null);
    settodate(null);
  };

  const getCurrentTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const date = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    const formattedTime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
    setLiveTime(formattedTime);
  };

  useEffect(() => {
    const timerID = setInterval(() => getCurrentTime(), 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  const sendMail = () => {
    console.log("Inside Send Mail");
    setloading(true);
    const activeSearchItem = tabSearchItems.newNotification;

    const payload = {
      userCode: "JICMXDKQ300922",
      patientName: activeSearchItem?.name,
      patientCode: activeSearchItem?.code,
      date: liveTime,
      subject: subject,
      content: body,
      status: "1",
      createdDate: liveTime,
      fromDate: liveTime,
      updatedDate: liveTime,
      createdBy: testParam,
      updatedBy: testParam,
      trigeredBy: testParam,
      trigeredTo: activeSearchItem?.code,
      trigeredUsertype: "P",
      fromName: toName,
    };

    console.log(payload, "Payload");

    notificationScreens
      .sendnotification(payload)
      .then((res) => {
        console.log(res?.data, "POST API RESULT");
        if (res?.data?.[0] === 1) {
          setsubject(null);
          setbody(null);
          setShow(false);
          setretrivedData(null);

          setTabSearchItems((prev) => ({ ...prev, newNotification: null }));

          showSuccess("Notifications Sent Successful");
        }
      })
      .catch((error) => {
        let errorMessage = "Error sending mail";
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          errorMessage = errorData.message;
          if (
            errorData.details &&
            errorData.details.length > 1 &&
            errorData.details[1]
          ) {
            errorMessage += `: ${errorData.details[1]}`;
          }
        }

        showError(errorMessage);
      });
  };

  return (
    <>
      <>
        <div>
          <Sidebar
            visible={show}
            position="right"
            onHide={() => {
              setShow(false);
              setfromdate(null);
              settodate(null);
              setTabSearchItems(" ");
            }}
            className="w-[500px]"
          >
            <Toast ref={toast} />
            <h2 className="font-bold text-2xl leading-6 tracking-normal text-left text-[#262626]">
              Notification
            </h2>

            <TabView
              className="mt-10"
              activeIndex={activeIndex}
              onTabChange={(e) => onTabChange(e)}
            >
              <TabPanel header="Received" className="mr-4">
                <div className="flex flex-row items-center relative w-96 mb-6 overflow-hidden">
                  <InputText
                    value={tabSearchItems["received"]?.name || ""}
                    placeholder="Search..."
                    className="border rounded border-solid border-[#E2E2E2] box-border w-full pl-3 pr-10"
                    onClick={() => togglePatientSearchbar()}
                  />

                  <img
                    src={search}
                    alt=""
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 cursor-pointer"
                    onClick={() => {
                      setLastSearchTrigger("received");
                      togglePatientSearchbar();
                    }}
                  />
                </div>

                <div>
                  <Calendar
                    value={fromdate}
                    placeholder="Enter From Date"
                    onChange={(e) => setfromdate(e.value)}
                    showIcon
                    className="border rounded border-solid border-[#E2E2E2] box-border w-40 mb-9"
                  />
                  <Calendar
                    value={todate}
                    placeholder="Enter To Date"
                    onChange={(e) => settodate(e.value)}
                    showIcon
                    className="border rounded border-solid border-[#E2E2E2] box-border w-40 mb-9 ml-10"
                  />
                  <button
                    className="w-40 h-10 border border-[#004172] bg-[#004172] text-[#FFFFFF] rounded-md font-monospace  ml-3 font-bold mb-10 pi pi-print"
                    onClick={() => window.print()}
                  >
                    {" "}
                    Print
                  </button>
                  <button
                    className="w-40 h-10 border border-[#004172] bg-[#004172] text-[#FFFFFF] rounded-md font-monospace  ml-3 font-bold mb-10"
                    onClick={() => onSearch("received")}
                  >
                    Search{" "}
                  </button>
                </div>
                {sentMailData.length > 0 &&
                  sentMailData.map((data, index) => (
                    <>
                      <div
                        className="inline-block rounded-xl bg-white border border-gray-300 mb-4 overflow-hidden"
                        key={index}
                      >
                        <div className="flex flex-row items-center">
                          <img src={blueline} alt="" className="" />
                          <div className="flex flex-col ml-4 ">
                            <h1 className="font-bold mb-3">{data?.subject}</h1>
                            <h1 className="text-sm ">{data?.content}</h1>
                            <Divider style={{ marginTop: "10px" }} />
                            <h1 className="text-sm mt-4 pi pi-calendar">
                              {data?.date && data.date.substring(0, 10)}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <Paginator
                        first={(currentPage - 1) * pageSize}
                        rows={pageSize}
                        totalRecords={notificationData.length}
                        onPageChange={onPageChange}
                        className=""
                      />
                    </>
                  ))}
              </TabPanel>
              <TabPanel header="Sent" className="mr-4">
                <div className="flex flex-row items-center relative w-96 mb-6 overflow-hidden">
                  <InputText
                    value={tabSearchItems["sent"]?.name || ""}
                    placeholder="Search...."
                    className="border rounded border-solid border-[#E2E2E2] box-border w-full pl-3 pr-10"
                    onClick={() => togglePatientSearchbar()}
                  />

                  <img
                    src={search}
                    alt=""
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 cursor-pointer"
                    onClick={() => {
                      setLastSearchTrigger("sent");
                      togglePatientSearchbar();
                    }}
                  />
                </div>

                <div>
                  <Calendar
                    value={fromdate}
                    placeholder="Enter From Date"
                    onChange={(e) => setfromdate(e.value)}
                    showIcon
                    className="border rounded border-solid border-[#E2E2E2] box-border w-40 mb-9"
                  />
                  <Calendar
                    value={todate}
                    placeholder="Enter To Date"
                    onChange={(e) => settodate(e.value)}
                    showIcon
                    className="border rounded border-solid border-[#E2E2E2] box-border w-40 mb-9 ml-10"
                  />
                  <button
                    className="w-40 h-10 border border-[#004172] bg-[#004172] text-[#FFFFFF] rounded-md font-monospace  ml-3 font-bold mb-10"
                    onClick={() => window.print()}
                  >
                    Print{" "}
                  </button>
                  <button
                    className="w-40 h-10 border border-[#004172] bg-[#004172] text-[#FFFFFF] rounded-md font-monospace  ml-3 font-bold mb-10"
                    onClick={() => onSearch("sent")}
                  >
                    Search{" "}
                  </button>
                </div>

                {currentData.length > 0 &&
                  currentData.map((data, index) => (
                    <div
                      className="inline-block rounded-xl bg-white border border-gray-300 mb-4 w-96 overflow-hidden"
                      key={index}
                    >
                      <div className="flex flex-row items-center">
                        <img src={blueline} alt="" className="" />
                        <div className="flex flex-col ml-4 ">
                          <h1 className="font-bold mb-3">{data?.subject}</h1>
                          <h1 className="text-sm ">{data?.content}</h1>
                          <Divider style={{ marginTop: "10px" }} />
                          <h1 className="text-sm mt-4 pi pi-calendar">
                            {data?.date && data.date.substring(0, 10)}
                          </h1>
                        </div>
                      </div>
                    </div>
                  ))}
                <Paginator
                  first={(currentPage - 1) * pageSize}
                  rows={pageSize}
                  totalRecords={sendNotification.length}
                  onPageChange={onPageChange}
                  className=""
                />
              </TabPanel>
              <TabPanel header="New Notification" className="mr-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="nameInput"
                    className="block text-sm font-medium text-black font-medium mb-4"
                  >
                    From
                  </label>
                  <InputText
                    value={toName}
                    disabled
                    placeholder="From...."
                    // onChange={(e) => setValue(e.target.value)}
                    className="border rounded border-solid border-[#E2E2E2] box-border w-full pl-3 pr-10"
                  />
                </div>
                <div className="flex flex-col relative">
                  <label
                    htmlFor="nameInput"
                    className="block text-sm font-medium text-black mb-4 mt-8"
                  >
                    To
                  </label>
                  <div className="relative">
                    <InputText
                      value={tabSearchItems["newNotification"]?.name || ""}
                      placeholder="Search..."
                      className="border rounded border-solid border-[#E2E2E2] box-border w-full pl-3 pr-10"
                    />
                    <img
                      src={search}
                      alt=""
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={() => {
                        setLastSearchTrigger("new");
                        togglePatientSearchbar();
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor="nameInput"
                    className="block text-sm font-medium text-black font-medium mb-4 mt-8"
                  >
                    Subject
                  </label>
                  <InputText
                    value={subject}
                    placeholder="Subject...."
                    onChange={(e) => setsubject(e.target.value)}
                    className="border rounded border-solid border-[#E2E2E2] box-border w-full pl-3 pr-10"
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="nameInput"
                    className="block text-sm font-medium text-black font-medium mb-4 mt-8"
                  >
                    Message
                  </label>
                  <InputTextarea
                    value={body}
                    placeholder="Type Here ......"
                    rows={10}
                    cols={30}
                    onChange={(e) => setbody(e.target.value)}
                    className="border rounded border-solid border-[#E2E2E2] box-border w-full pl-3 pr-10"
                  />
                </div>
                <button
                  className="w-64 h-10 border border-[#004172] bg-[#004172] text-[#FFFFFF] first-letter:font-bold font-monospace  rounded-md mt-6 ml-20"
                  onClick={sendMail}
                >
                  Send Notification
                </button>
              </TabPanel>
            </TabView>
          </Sidebar>
        </div>
      </>

      <PatientSearch
        showbar={patientSearch}
        setshowBar={togglePatientSearchbar}
        onSelect={handleSelectedData}
      />
    </>
  );
}
