import React, { useEffect, useState } from "react";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Paper,
} from "@mui/material";
import serviceEnquiryService from "../../services/serviceEnquiryService";
import "../../../src/App.css";
export default function ServiceEnquiry(props) {
  console.log(props, "PROPSYash");
  const [enquiryFormData, setenquiryFormData] = useState(null);

  const servideEnquiryFom = async () => {
    try {
      const result = await serviceEnquiryService.serviceEnquiryForm(
        props?.enquiryId
      );
      console.log(result?.data, "ResultserviceEnquiryForm");
      setenquiryFormData(result?.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchEnquiryForm = async () => {
      await servideEnquiryFom();
    };

    fetchEnquiryForm();
  }, [props?.enquiryId]);

  return (
    <>
      <div>
        {enquiryFormData && (
          <>
            <div className="w-118 h-10 top-98 left-47 bg-lightBlue bg-opacity-5.5 overflow-auto">
              <Typography className="font-poppins text-base font-semibold leading-6 mt-2 ml-2 text-left">
                Enquiry Id - {props?.enquiryId}
              </Typography>
            </div>
            <TableContainer
              component={Paper}
              style={{ width: "100%", height: "36vw", overflowX: "auto" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: 600,
                        textAlign: "left",
                      }}
                    >
                      Status Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: 600,
                        textAlign: "left",
                      }}
                    >
                      Status Date
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: 600,
                        textAlign: "left",
                      }}
                    >
                      Created By
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {enquiryFormData?.map((notifi) => (
                    <TableRow key={notifi.id}>
                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi?.statusName}
                      </TableCell>

                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi?.statusDate}
                      </TableCell>

                      <TableCell style={{ fontSize: "13px", fontWeight: 500 }}>
                        {notifi?.createdBy}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider />
          </>
        )}
        <div className="bg-white"></div>
      </div>
    </>
  );
}
