import React, { useEffect, useState } from 'react';
  import './App.css';
  import OurRoutes from './routes';
  import { ToastContainer } from 'react-toastify';
  import "react-toastify/dist/ReactToastify.css"; 
  import { ThemeProvider, createTheme } from '@mui/material';
  import { PrimeReactProvider } from 'primereact/api';
  
  const theme = createTheme({
    typography: {
      fontFamily: [
        'Poppins', // Your preferred font family here, e.g., Arial
        'sans-serif',
      ].join(','),
    },
  });

  function App() {
    useEffect(() => {
      const resizeWindow = () => {
        const screenWidth = window.screen.availWidth;
        const newWidth = Math.floor(screenWidth * 0.67); // Set width to 67% of screen width
        window.resizeTo(newWidth, window.innerHeight);
      };
  
      resizeWindow(); // Resize window when the component mounts
  
      // Clean up event listener when component unmounts
      return () => {
        window.removeEventListener('resize', resizeWindow);
      };
    }, []);
    return (
      <ThemeProvider theme={theme}>
         <PrimeReactProvider>
          <OurRoutes />
          <ToastContainer autoClose={2000}/>
          </PrimeReactProvider>
     </ThemeProvider>
    );
  }

  export default App;
