import React, { useEffect, useState } from 'react';
import {
  Typography,
} from '@mui/material';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from "primereact/dropdown";
import apiService from '../../services/apiservice';

import { toast } from "react-toastify";
const AssignEnquiry = (props) => {
  const userObj = JSON.parse(localStorage.getItem("loginObj"));
const userType = props.userType
  const [selectedUser, setSelecteduser] = useState('');
  const [userName, setuserName] = useState('');

  const roleCode = localStorage.getItem('userRole') 

  let location
  if (roleCode == 'PRADMIN') {
    location = JSON.parse(localStorage.getItem('location'));
  }
  const [selectedOption, setSelectedOption] = useState( roleCode == 'GROUPPRADMIN'? 'assign': 'self');
  const [userList, setuserList] = useState([]);


  const options = [
    { label: 'Self Assign', value: 'self' },
    { label: 'Assign to User', value: 'assign' }
  ];

  const handleOptionChange = (event) => {
    setSelectedOption(event.value);
  };
  const handleChange=(e)=>{
    setSelecteduser(e.target.value) 
    const name = userList.find(itmList => itmList.code=== e.target.value).userName;

    setuserName(name)
  }
const save=async ()=>{
if(selectedOption  == 'assign' && selectedUser ==''){
  return toast.error('Please select user')
}

  let payload=[]
  let payload1={
    id:props.selected.id,
    modifiedBy:userObj.user.code  ,
    executiveFlag : userType==='BO' ?0 :1,
  }
  if(userType === 'BO'){
    payload1.status= props.selected.status == 11 ? 1 : props.selected.status
    payload1.assignedUser=selectedOption  == 'self' ? userObj.user.code :selectedUser
payload1.assignedUserName=userName
payload1.conciergeUserCode =props.selected.conciergeUserCode ?props.selected.conciergeUserCode :null
payload1.conciergeName=props.selected.conciergeName ?props.selected.conciergeName :null
  }
  else{
    payload1.assignedUser = userObj.user.code 

    payload1.assignedUserName = userObj.user.userName ?  userObj.user.userName : userObj.user.firstName 
    
    payload1.conciergeUserCode = selectedUser

    payload1.conciergeName=userName

    payload1.status= props.selected.status == 11 ? 1 : props.selected.status
    
  }
  payload.push(payload1)
  try{
  const res = await apiService.updateAssidgn(payload);
  if (!res.message) {
toast.success('User Assigned Successfully')
props.onClose()
// try {
//   props.selected.activeServiceStatus = 'Y'
//   props.selected.billingStatus = 1


//   delete props.selected.pagination
//   props.selected.status = 1 
//   let payload = []


//   payload.push(props.selected)
//   const res1 = await apiService.updateEnqList(payload);
//   if (!res1.message) {

//   }
//   props.onClose()

// } catch (error) {
//   console.error('Error fetching enquiry list:', error);
// }
  }
} catch (error) {
  console.error('Error fetching enquiry list:', error);
}
}

  const getuserList = async () => {
    let payload ={}
    try {
      // if(roleCode === 'PRADMIN'){
      //   payload.hospitalId = location.hospitalId
      // }
      payload.roleCode= userType =='BO'? 'GROUPPRADMIN' :'CE'
      const res = await apiService.getUserList(payload);
      if (!res.message) {
        console.log(res.data)
        setuserList(res.data)
      }
    } catch (error) {
      console.error('Error fetching enquiry list:', error);
    }
  };
  useEffect(() => {
    getuserList();
  }, []);
  return (
    <>
      {userType == 'BO' && (
        <div>
      <Typography variant="h6" gutterBottom>
        Please select user your option:
      </Typography>

      <div className="radio-button-container justify-items-center mt-16">
        {options.map((option, index) => (
          <div>
          <div key={index} className="radio-button-item">
            <RadioButton
              inputId={`option${index}`}
              name="options"
              value={option.value}
              onChange={handleOptionChange}
              checked={selectedOption === option.value}
              // disabled={roleCode =='GROUPPRADMIN' && option.value == 'self' }
            />
            <label className='ml-4' htmlFor={`option${index}`}>{option.label}</label>
          </div>
      
            </div>

          
        ))}
           { selectedOption== 'self' && <div className="mr-5 justify-end" >
              <button className="w-32  h-9 bg-[#004172] text-white rounded-md"
                onClick={save}
                 >
Save              </button>

            </div>}
      </div>
      {(selectedOption==='assign' && userType == 'BO' )&&

(<div>
<div className='mt-6' >
      <Typography variant="h6"  gutterBottom>
        Please select to Assign:
      </Typography>
      </div>

      <div  className='mt-6 flex grid grid-cols-2 gap-5'>
              {/* <InputText  value={selectedRow.hospitalName}  id="name" className="border border-gray-300 rounded-md p-2 w-full" /> */}
              <Dropdown
                options={userList}
                optionLabel="userName" // This tells Dropdown how to display the options
                placeholder="Select a user"
                optionValue='code'
                value={selectedUser}
                onChange={(e)=>handleChange(e)}
                filter
                style={{ height: '50px' }}
                className="border border-gray-300 rounded-md p-2 "
               />
                      <div className="mt-2 justify-end" >
              <button className="w-32 h-9 bg-[#004172] text-white rounded-md"
                onClick={save}
                 >
Save              </button>

            </div>
            </div>
            
             </div>
            )}
             </div>)}
             {userType == 'CE' &&

(<div>
<div className='mt-6' >
      <Typography variant="h6"  gutterBottom>
        Please select Conceirge Excecutive to Assign:
      </Typography>
      </div>

      <div  className='mt-6 flex grid grid-cols-2 gap-5'>
              {/* <InputText  value={selectedRow.hospitalName}  id="name" className="border border-gray-300 rounded-md p-2 w-full" /> */}
              <Dropdown
                options={userList}
                optionLabel="userName" // This tells Dropdown how to display the options
                placeholder="Select a user"
                optionValue='code'
                value={selectedUser}
                onChange={(e)=>handleChange(e)}
                filter
                style={{ height: '50px' }}
                className="border border-gray-300 rounded-md p-2 "
               />
                      <div className="mt-2 justify-end" >
              <button className="w-32 h-9 bg-[#004172] text-white rounded-md"
                onClick={save}
                 >
Save              </button>

            </div>
            </div>
            
             </div>)}
    
    </>
  )
}

export default AssignEnquiry;
