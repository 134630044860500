import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../App.css";
import CureBayLogo from "../../assets/images/CureBayLogo.svg";
import dashboardIcon from "../../assets/images/dashboard.svg";
import dashboardIcon2 from "../../assets/images/dashboard2.svg";
import enqiryIcon1 from "../../assets/images/enquirIcon1.svg";
import enqiryIcon2 from "../../assets/images/enquiryIcon2.svg";



import enquiry1 from "../../assets/images/enquiry.svg";
import enquiry2 from "../../assets/images/enquiry2.svg";

import { APP_ROUTES } from "../../routes/approutes";

const NavBar = () => {
  const [activeTab, setActiveTab] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const roleCode = localStorage.getItem('userRole')

  const tabs = [
    {
      id: 0,
      name: "Home",
      icon: dashboardIcon,
      icon2:dashboardIcon2,
      route: APP_ROUTES.DASHBOARD,
    },
    {
      id: 1,
      name: "Enquiry List",
      icon: enqiryIcon1,
      icon2: enqiryIcon2,

      route: APP_ROUTES.ENQLIST,
    },

    {
      id: 2,
      name: "Services",
      icon: enquiry1,
      icon2: enquiry2,

      route: APP_ROUTES.SERVICE,
    },
  ];

  const tabs2 = [

    {
      id: 1,
      name: "Patient Approval",
      icon: enqiryIcon1,
      icon2: enqiryIcon2,

      route: APP_ROUTES.PRALIST,
    },
    {
      id: 2,
      name: "Billing Approval",
      icon: enqiryIcon1,
      icon2: enqiryIcon2,

      route: APP_ROUTES.BILLAPPROVE,
    },
  ];

  useEffect(() => {
    const currentTab =roleCode != 'PRADMIN'? tabs.find((tab) => tab.route === location.pathname) :  tabs2.find((tab) => tab.route === location.pathname);

    setActiveTab(currentTab ? currentTab.id : null);
  }, [location, tabs]);

  const handleNavigation = (path, tabId) => {
    setActiveTab(tabId);
    history.push(path);
  };

  return (
    <div className="flex flex-col w-[15%] h-[100%] bg-[#18406E] fixed z-[1]">
      <img src={CureBayLogo} alt="Company Logo" className="mb-10 h-12 mt-3 mr-5" />
      {roleCode != 'PRADMIN' ? 
      tabs.map((tab) => (
        <>
        
        {/* <div  className=" bg-[#18406E] w-full ml-4 h-[70px] rounded-full ">
            </div> */}
        <div
          key={tab.id}
          onClick={() => handleNavigation(tab.route, tab.id)}
          className={`flex items-center  mt-5 cursor-pointer relative active-tab-icon ${
            activeTab === tab.id ? "text-primary" : "text-white"
          }`}
        >
      
          <div
            className={`absolute inset-0  bg-white w-full h-[50px] rounded-l-full ${
              activeTab !== tab.id && "hidden"
            }`}
            style={{ zIndex: 0 }}
          ></div>
         
          <img
            src={    activeTab === tab.id ? tab.icon : tab.icon2}
            alt={`${tab.name} Icon`}
            className={`mr-2 z-10 ${
              activeTab === tab.id ? "text-primary " : "text-white"
            }`}
            style={{
              width: "20px",
              height: "20px",
              marginTop: "0",
              marginLeft: "10px",
            }}
          />
          <h1
            className="text-sm font-bold z-10 p-4 mr-6"
            style={{ whiteSpace: "nowrap", marginTop: "0" }}
          >
            {tab.name}
          </h1>
        </div>
</>
      ))
      :  tabs2.map((tab) => (
        <>
        
        {/* <div  className=" bg-[#18406E] w-full ml-4 h-[70px] rounded-full ">
            </div> */}
        <div
          key={tab.id}
          onClick={() => handleNavigation(tab.route, tab.id)}
          className={`flex items-center  mt-5 cursor-pointer relative active-tab-icon ${
            activeTab === tab.id ? "text-primary" : "text-white"
          }`}
        >
      
          <div
            className={`absolute inset-0  bg-white w-full h-[50px] rounded-l-full ${
              activeTab !== tab.id && "hidden"
            }`}
            style={{ zIndex: 0 }}
          ></div>
         
          <img
            src={    activeTab === tab.id ? tab.icon : tab.icon2}
            alt={`${tab.name} Icon`}
            className={`mr-2 z-10 ${
              activeTab === tab.id ? "text-primary " : "text-white"
            }`}
            style={{
              width: "20px",
              height: "20px",
              marginTop: "0",
              marginLeft: "10px",
            }}
          />
          <h1
            className="text-sm font-bold z-10 p-4 mr-6"
            style={{ whiteSpace: "nowrap", marginTop: "0" }}
          >
            {tab.name}
          </h1>
        </div>
</>
      ))}
    </div>
  );
};

export default NavBar;
